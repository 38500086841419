import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Logo } from '../Logo';

export const NetworkErrorFallback: FC<{ onClick?: () => void }> = ({ onClick }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            bgcolor="#0E0E2C"
            py={6}
        >
            <Box />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                height="120px"
            >
                <Typography variant="h5" color="#FDFDFC" align="center" paragraph>
                    {`Unable to connect just now :(`}
                </Typography>
                <Box display="flex" justifyContent="space-around" alignItems="center">
                    {onClick && (
                        <Button
                            variant="contained"
                            color="info"
                            startIcon={<RefreshIcon />}
                            onClick={onClick}
                            sx={{ mx: 1.5 }}
                        >
                            {`Try again`}
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        // color="#FDFDFC"
                        startIcon={<HelpOutlineIcon />}
                        href="https://home.golendica.com/#contact"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ mx: 1.5, color: '#A3A3B4', borderColor: '#A3A3B4' }}
                    >
                        {`Get help`}
                    </Button>
                </Box>
            </Box>
            <Logo dark={false} height={18} />
        </Box>
    );
};
