import { Box, Typography } from '@mui/material';
import { FC } from 'react';

export type TagColor =
    | 'primary'
    | 'error'
    | 'info'
    | 'text'
    | 'dark'
    | 'success'
    | 'warning'
    | 'default';

export interface TagProps {
    color?: TagColor | string;
    label: string;
}

const colorMapping: { [key: string]: string } = {
    primary: 'primary.main',
    error: 'error.main',
    info: 'info.light',
    text: 'info.main',
    dark: 'secondary.main',
    success: 'success.main',
    warning: 'warning.main',
    default: '#ececf5',
};

export const Tag: FC<TagProps> = ({ color = 'info', label }) => {
    return (
        <Box
            sx={{
                height: 20,
                bgcolor: `${colorMapping[color] || color}`,
                px: 0.5,
                borderRadius: 1,
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Typography variant="caption" color="common.white">
                {label}
            </Typography>
        </Box>
    );
};
