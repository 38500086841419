import axios from 'axios';

interface SlackBlock {
    type: string;
    text: {
        type: string;
        text: string;
    };
}

const slack = axios.create({ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

const FE_ERROR_NOTIFICATION_WEBHOOK =
    'https://hooks.slack.com/services/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';
const FE_CUSTOMER_SUPPORT_WEBHOOK =
    'https://hooks.slack.com/services/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';

export const postError = async (error: Error, ...props: any) => {
    const { data } = await slack.post(
        FE_ERROR_NOTIFICATION_WEBHOOK,
        JSON.stringify({
            text: `${error.message}${props}`,
            token: `xapp-1-A05GKV0G83E-5548952653559-f3869689300328cefec9e2e034aba50904ef51bffc8fc32d61cd53c494c73cf3`,
        })
    );
    return data;
};

export const postCustomerSupport = async (blocks: SlackBlock[]) => {
    const { data } = await slack.post(
        FE_CUSTOMER_SUPPORT_WEBHOOK,
        JSON.stringify({
            blocks,
            token: 'xoxb-1491061381639-5918682042662-cN81TkXNK012YSCmBibcYlDp',
        })
    );
    return data;
};
