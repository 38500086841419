import mitt from 'mitt';

export type EventData = {
    eventName: string;
    displayMessage: string;
};

export type Events = {
    success: EventData;
    exit: EventData;
};

export const emitter = mitt<Events>();

// // Example emitting success event
// export const emitSuccess = (event: EventData) => {
//     emitter.emit('success', event);
// };

// // Example emitting exit event
// export const emitExit = (event: EventData) => {
//     emitter.emit('exit', event);
// };

// // Example listening to all events
// emitter.on('*', (type, e) => console.log('emitter.ts', type, e));

// // Example listening to exit events
// emitter.on('exit', e => console.log('emitter.ts', 'exit', e));
