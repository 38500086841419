import { Box, Link, Typography } from '@mui/material';
import { Share, Email, LinkedIn, Twitter } from '@mui/icons-material';

const socialMedia = [
    {
        icon: Share,
        link: 'mailto:?subject=PayLater by Lendica - Fast and affordable business finance&body=Lendica provides fast and affordable finance. Try "PayLater" offer calculator: https://paylater.app.golendica.com/offer-calculator',
    },
    {
        icon: Email,
        link: 'mailto:info@golendica.com',
    },
    {
        icon: LinkedIn,
        link: 'https://www.linkedin.com/company/lendica/',
    },
    {
        icon: Twitter,
        link: 'https://twitter.com/GoLendica',
    },
];

export const SocialMediaAndPolicyContainer = () => {
    return (
        <Box sx={{ color: '#8C8CA1', mt: 4 }}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                {socialMedia.map(({ icon: Icon, ...media }, index) => (
                    <Link key={index} href={media.link} target="_blank" sx={{ color: '#8C8CA1' }}>
                        <Icon
                            key={index}
                            sx={{
                                'fontSize': 18,
                                'px': 0.5,
                                '&:hover': { color: '#c2c2c2' },
                            }}
                        />
                    </Link>
                ))}

                <Typography
                    variant="caption"
                    fontSize={theme => theme.typography.pxToRem(11)}
                    sx={{ pl: 0.5, color: '#8C8CA1' }}
                >
                    <Link
                        underline="hover"
                        href="https://golendica.com/terms"
                        target="_blank"
                        sx={{ color: '#8C8CA1' }}
                    >
                        Terms of Use
                    </Link>
                    &nbsp;|&nbsp;
                    <Link
                        underline="hover"
                        href="https://golendica.com/privacy"
                        target="_blank"
                        sx={{ color: '#8C8CA1' }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            </Box>

            <Typography
                fontSize={theme => theme.typography.pxToRem(11)}
                sx={{
                    mt: 1,
                    mb: 4,
                    textAlign: 'center',
                }}
            >
                Copyright © 2023 Lendica Corp. All rights reserved.
            </Typography>
        </Box>
    );
};
