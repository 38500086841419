/* https://docs.cypress.io/guides/references/best-practices#Selecting-Elements describes the following 
as a Cypress "best practice": "use data-* attributes to provide context to your selectors and isolate 
them from CSS or JS changes" */

export enum TestId {
    Select_one_payment_term_TestId = "Select_one_payment_term_TestId",
    Pay_this_invoice_over_time_TestId = "Pay_this_invoice_over_time_TestId",
    Fees_are_subject_to_change_TestId = "Fees_are_subject_to_change_TestId",
    Late_fee_PinnedSubheaderList_TestId = "Late_fee_PinnedSubheaderList_TestId",
    First_payment_PinnedSubheaderList_TestId = "First_payment_PinnedSubheaderList_TestId"
}