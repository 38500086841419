import axios from 'axios';

const proxyURL = 'https://micro-api-proxy-z8wfa.ondigitalocean.app/proxy/';

export const getCMSPartner = async (itemID: string) => {
    try {
        const resp = await axios.get(
            proxyURL + `api.webflow.com/v2/collections/653ffd0d225db746c6a6bf66/items/${itemID}`
        );
        return resp.data;
    } catch (error) {
        console.log('Error:', error);
    }
};
