import { Navigate, Outlet, useLocation } from 'react-router-dom';
import * as tokens from '../utils/auth';

export const ProtectedRoute = ({ redirectPath = '/' }) => {
    const location = useLocation();

    if (!tokens.accessToken.get() || !tokens.refreshToken.get()) {
        return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }

    return <Outlet />;
};
