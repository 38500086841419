import { Grid, Typography, Box } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    'arrow': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderLeft: `1px solid ${theme.palette.primary.main}`,
        width: 30,
        height: 30,
        transform: 'rotate(-45deg)',
        animation: '$slide 1.5s infinite',
    },
    '@keyframes slide': {
        '0%': {
            opacity: 0,
            transform: 'rotate(-45deg) translateY(-60px) translateX(60px)',
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
            transform: 'rotate(-45deg) translateY(-10px) translateX(10px)',
        },
    },
}));

export const ScrollArea = ({ title, to, container, offset }) => {
    const classes = useStyles();

    return (
        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-end"
            direction="column"
            style={{ cursor: 'pointer' }}
            onClick={() => {
                container.current.scroll({
                    top: to.current.offsetTop - (offset ? offset.current.offsetHeight : 0),
                    behavior: 'smooth',
                });
            }}
        >
            <Grid item component={Box} pt={8} pr={1}>
                <div className={classes.arrow}></div>
            </Grid>
            <Grid item>
                <Typography variant="overline">{title}</Typography>
            </Grid>
        </Grid>
    );
};
