declare global {
    interface Window {
        dataLayer: any[];
        icaGtag: (...args: any[]) => void;
        lendicaConfig: any;
    }
}

const POD_MEASUREMENT_ID = 'G-DRKXGBK0D0';

export function initAnalytics() {
    appendGTagToDocument();
    initGTag();
}

function appendGTagToDocument() {
    const script = document.createElement('script');

    script.src = `https://www.googletagmanager.com/gtag/js?id=${POD_MEASUREMENT_ID}`;
    script.async = true;

    document.body.appendChild(script);
}

function initGTag() {
    window.dataLayer = window.dataLayer || [];

    // window.icaGtag = (...args: any[]) => {
    //     // @ts-ignore
    //     window.dataLayer?.push(args);
    // }

    window.gtag = function () {
        // @ts-ignore
        dataLayer.push(arguments);
    };

    gtag('js', new Date());
    gtag('config', POD_MEASUREMENT_ID, { transport_url: 'https://gtm-pod.ue.r.appspot.com' });
}

export function lendicaInitEvent({ partner_company_uuid, partner_name, company_name }: any = {}) {
    window.lendicaConfig = {
        partner_company_uuid,
        partner_name,
        company_name,
    };

    if (partner_company_uuid) {
        gtag('event', 'lendica_init', {
            send_to: POD_MEASUREMENT_ID,
            partner_name: partner_name || location.hostname,
            partner_company_uuid: partner_company_uuid,
            company_name: company_name || '',
            ...(window.localStorage.getItem('ica_vik') && {
                visitor_id: window.localStorage.getItem('ica_vik'),
            }),
        });
    } else {
        gtag('event', 'lendica_unauthorized_init', {
            send_to: POD_MEASUREMENT_ID,
            partner_name: location.hostname,
            ...(window.localStorage.getItem('ica_vik') && {
                visitor_id: window.localStorage.getItem('ica_vik'),
            }),
        });
    }
}

export function payLaterRenderEvent() {
    gtag('event', 'paylater_render', {
        send_to: POD_MEASUREMENT_ID,
        partner_name: location.hostname,
    });
}

export function payLaterUpdateEvent({
    total,
    partner_invoice_uuid,
    partner_company_uuid,
    partner_name,
}: any = {}) {
    gtag('event', 'paylater_update', {
        send_to: POD_MEASUREMENT_ID,
        partner_name: partner_name || location.hostname,
        company_uuid: partner_company_uuid || 'NA',
        invoice_uuid: partner_invoice_uuid || 'NA',
        invoice_amount: total,
    });
}

export function checkoutBtnClickEvent({
    type,
    total,
    partner_invoice_uuid,
    partner_company_uuid,
    partner_name,
}: any = {}) {
    gtag('event', 'checkout_click', {
        send_to: POD_MEASUREMENT_ID,
        type: type,
        partner_name: partner_name || location.hostname,
        invoice_amount: total,
        company_uuid: partner_company_uuid || 'NA',
        invoice_uuid: partner_invoice_uuid || 'NA',
    });
}

export function learnMoreEvent({ partner_name }: any = {}) {
    gtag('event', 'learn_more', {
        send_to: POD_MEASUREMENT_ID,
        partner_name: partner_name || location.hostname,
    });
}

export function attachIBranchEventListeners() {
    const sidebarButtonNode = document
        ?.querySelector('#ibranch-root div')
        ?.shadowRoot?.querySelector('#btn-sidebar-lendica');

    if (sidebarButtonNode) {
        sidebarButtonNode?.addEventListener('mouseenter', () => {
            iBranchAnalyticsEvents('entry_hover');
        });

        sidebarButtonNode?.addEventListener('click', () => {
            iBranchAnalyticsEvents('open');
        });
    } else {
        // If the button is not yet present, retry after a short delay
        setTimeout(attachIBranchEventListeners, 1000);
    }
}

export function iBranchAnalyticsEvents(eventName: string, rest?: any) {
    gtag('event', `ibranch_${eventName}`, {
        send_to: POD_MEASUREMENT_ID,
        ...window.lendicaConfig,
        ...(window.localStorage.getItem('ica_vik') && {
            visitor_id: window.localStorage.getItem('ica_vik'),
        }),
        ...rest,
    });
}

export function adsAnalyticsEvent(eventName: string, rest: any) {
    gtag('event', `ad_${eventName}`, {
        send_to: POD_MEASUREMENT_ID,
        ...window.lendicaConfig,
        ...(window.localStorage.getItem('ica_vik') && {
            visitor_id: window.localStorage.getItem('ica_vik'),
        }),
        ...rest,
    });
}
