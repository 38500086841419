export enum TokenType {
    Refresh = 'ica_rfsh',
    Access = 'ica_acc',
}

const setToken = (type: TokenType, token: string) => window.localStorage.setItem(type, token);
const getToken = (type: TokenType) => window.localStorage.getItem(type);
const removeToken = (type: TokenType) => window.localStorage.removeItem(type);

export const refreshToken = {
    set: (token: string) => setToken(TokenType.Refresh, token),
    get: () => getToken(TokenType.Refresh),
    remove: () => removeToken(TokenType.Refresh),
}

export const accessToken = {
    set: (token: string) => setToken(TokenType.Access, token),
    get: () => getToken(TokenType.Access),
    remove: () => removeToken(TokenType.Access),
}