import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

export const VideoViewer: FC<{ videoUrl: string }> = ({ videoUrl }) => (
    <Box
        sx={{
            p: 2,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: videoUrl ? 'stretch' : 'center',
            flexDirection: 'column',
            boxSizing: 'border-box',
        }}
    >
        {videoUrl ? <embed src={videoUrl} style={{ flexGrow: 1 }} /> : <CircularProgress />}
    </Box>
);
