import { FeatureLayout, Logo, RoundedButton } from '@lendica/components'
import { Box, Typography, TextField, IconButton } from '@mui/material'
import { CheckCircleRounded } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { WarningAmber } from '@mui/icons-material';

export const ProductPaused = () => {
    const navigate = useNavigate()

    return (
        <FeatureLayout>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxSizing: 'border-box', px: 4 }}>

                <WarningAmber color="warning" style={{ fontSize: 80 }} />
                <Typography variant="h5" sx={{ mt: 4 }}>Your product is paused. Please contact us for more information.</Typography>

                <RoundedButton
                    size="medium"
                    startIcon={<ArrowBackIcon />}
                    sx={{ mt: 4, mx: 2 }}
                    onClick={() => navigate('/wallet')}
                >
                    Go Back
                </RoundedButton>
            </Box>
        </FeatureLayout>
    )
}