import { Component, ErrorInfo, ReactNode, useCallback, useState } from 'react';
import { postError } from '@lendica/api';

export const useAsyncError = () => {
    const [_, setError] = useState();
    return useCallback(
        (e: Error) => {
            setError(() => {
                throw e;
            });
        },
        [setError]
    );
};

interface ErrorBoundaryProps {
    fallback: ReactNode;
    children?: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

const formatErrorStack = (componentStack: string) => {
    const stack = componentStack.split(/\r?\n/);
    return stack.slice(0, 2).join('\n');
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error(error, errorInfo);
        try {
            postError(error, formatErrorStack(errorInfo.componentStack));
        } catch (error) {
            console.error('Error posting to slack channel', error);
        }
    }

    public render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}
