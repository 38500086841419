import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, TextField, Typography } from '@mui/material';
import { Backdrop, RoundedButton } from '@lendica/components';
import * as api from '../api';
import { PhoneFormat } from '../utils/components';

const validationSchema = yup.object({
    phone: yup
        .string()
        .test('len', 'Please provide a valid phone number', val => val?.length === 10)
        .required('Phone is required'),
});

export const Phone = ({ onNavForward, setPhone, heading, body }: any) => {
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: validationSchema,
        onSubmit: async ({ phone }) => {
            try {
                setLoading(true);
                await api.phone(phone);

                setPhone(phone);
                onNavForward();
                setLoading(false);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <Backdrop loading={loading}>
            <Box
                component="form"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                pb={4}
                flex={1}
                onSubmit={formik.handleSubmit}
            >
                <Typography variant="h4" fontSize={16} mb={3}>
                    {heading ? heading : 'Welcome to Lendica!'}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        backgroundColor: '#F2F2F8',
                        color: '#282761',
                        fontWeight: 100,
                        fontSize: 28,
                        borderRadius: '10px',
                        p: 2,
                        mb: 3,
                    }}
                >
                    {body ? body : 'Get ready to be funded in two minutes 🚀.'}
                </Typography>
                <TextField
                    label="Phone number"
                    name="phone"
                    fullWidth
                    margin="normal"
                    InputProps={{ inputComponent: PhoneFormat }}
                    inputProps={{ 'data-testid': 'phone' }}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.phone && formik.errors.phone)}
                    helperText={
                        formik.touched.phone && formik.errors.phone ? formik.errors.phone : ' '
                    }
                />

                <Box display="flex" flexDirection="column" justifyContent="center" mt={1}>
                    <Typography variant="caption" align="center" mb={1} mt={1.5}>
                        We'll send you a one-time code to verify.
                    </Typography>
                </Box>

                <RoundedButton
                    type="submit"
                    size="medium"
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{ marginTop: 2 }}
                    data-testid="submit"
                >
                    Send code
                </RoundedButton>
            </Box>
        </Backdrop>
    );
};

export default Phone;
