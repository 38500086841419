import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReportOffIcon from '@mui/icons-material/ReportOff';

export const Status = ({
    title = 'Something went wrong :(',
    icon = <ReportOffIcon color="error" sx={{ fontSize: 110 }} />,
    onClick,
}) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 7,
                overflowY: 'auto',
            }}
        >
            {icon}
            <Typography
                variant="h5"
                sx={{
                    textAlign: 'center',
                    textWrap: 'balance',
                }}
            >
                {title}
            </Typography>
            {onClick && (
                <Button
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={onClick}
                    startIcon={<ArrowBackIcon />}
                >
                    Go Back
                </Button>
            )}
        </Box>
    );
};
