export const numberWithCommas = (number, fractionDigits = 0) => {
    const formattedNumber = parseFloat(number).toFixed(fractionDigits);
    return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDate = date => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
};

export const calculateEndDate = (startDate, termLengthInDays) => {
    const start = new Date(startDate);
    const end = new Date(start);
    end.setDate(end.getDate() + termLengthInDays);
    return end;
};

export const mapToProductName = name => {
    const productNames = {
        fundnow: 'FundNow',
        paylater: 'PayLater',
        drawdown: 'DrawDown',
    };

    return productNames[name] || name;
};
