import { BankAccount, CompanyBankStatus, BankDisplayData, DataConnection } from './bankTypes';
import { Company } from '@lendica/utils';
import { postCustomerSupport } from '@lendica/api';

export class BankService {
    constructor() {
        // this.company = company;
    }

    // company: Company;
    // bankStatus: CompanyBankStatus = CompanyBankStatus.HAS_NONE;

    public static hasDefaultBank(company: Company): boolean {
        return Boolean(company.bank_account && company.bank_routing && company.bank_name);
    }

    public static getConnectionStatus(bankAccounts: BankAccount[]): CompanyBankStatus {
        if (bankAccounts.length === 0) {
            return CompanyBankStatus.HAS_NONE;
        }
        if (bankAccounts.length === 1) {
            return CompanyBankStatus.HAS_SINGLE_ACTIVE_CONNECTED;
        }
        if (bankAccounts.length > 1) {
            return CompanyBankStatus.HAS_MULTIPLE_ACTIVE_CONNECTED;
        }
        return CompanyBankStatus.HAS_NONE;
    }

    public static filterConnectedBanks = (connections: DataConnection[]) => {
        // Filter active plaid connections with display data
        const plaidConnections = connections.filter(
            (connection: DataConnection) =>
                connection.saas_name === 'plaid' &&
                connection.active &&
                connection.display_data!.length > 0
        );
        if (plaidConnections.length === 0) {
            return [];
        }
        return plaidConnections;
    };

    public static getBankDisplayData = (connections: DataConnection[]): BankDisplayData[] => {
        // Extract list of display data in each connection and return one flat list
        const activeConnections = BankService.filterConnectedBanks(connections);
        return activeConnections.flatMap((connection: DataConnection) => connection.display_data!);
    };

    public static getBankAccounts = (connections: DataConnection[]): BankAccount[] | [] => {
        const bankList = BankService.getBankDisplayData(connections);
        if (bankList.length === 0) {
            const icaPlaid = localStorage.getItem('ica_plaid');
            if (!!icaPlaid) {
                return JSON.parse(icaPlaid).map((connection: any) => ({
                    bank_name: connection.institution_name,
                    bank_account: '1'.repeat(5) + connection.mask,
                    bank_routing: '1'.repeat(9),
                }));
            }
            return [];
        }

        return bankList.map((bank: BankDisplayData) => ({
            bank_name: bank.institution,
            bank_account: '1'.repeat(5) + bank.account_number.slice(-4),
            bank_routing: '1'.repeat(9),
        }));
    };

    public static contactSupport = async (title: string, message: string) => {
        const blocks = [
            {
                type: 'header',
                text: {
                    type: 'plain_text',
                    text: title,
                    emoji: true,
                },
            },
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: message,
                },
            },
        ];
        await postCustomerSupport(blocks);
    };
}
