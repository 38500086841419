import { FC, ReactNode, useEffect, useState } from 'react';
import {
    Snackbar as MuiSnackbar,
    SnackbarContent as MuiSnackbarContent,
    LinearProgress,
    Box,
    Button,
    IconButton,
} from '@mui/material';
import { Campaign as CampaignIcon, Close as CloseIcon } from '@mui/icons-material';
import NotificationsRounded from '@mui/icons-material/NotificationsRounded';
import Tooltip from '@mui/material/Tooltip';

export interface SnackBarProps {
    position: 'left' | 'right';
    children?: ReactNode;
    autoHideDuration?: number;
    handleClose(): void;
    handleMute(): void;
    cta?(): void;
    handleLearnMore?: () => void;
    ad?: {
        ad_type?: string;
        campaign?: string; // id of the campaign
        content_type?: 'cms' | 'rich_text' | 'key_pairs' | 'plain_text';
        content_value?: string;
        coupon_code?: string;
        name?: string;
    };
}

export const SnackBar: FC<SnackBarProps> = ({
    position = 'left',
    children,
    autoHideDuration = 10000,
    handleClose,
    handleMute,
    cta,
    handleLearnMore,
    ad,
}) => {
    return (
        <MuiSnackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: position }}
            sx={{ width: 705, backgroundColor: '#EDEDF0' }}
        >
            <MuiSnackbarContent
                message={
                    children ? (
                        children
                    ) : (
                        <SnackbarContent
                            open
                            autoHideDuration={autoHideDuration}
                            handleClose={handleClose}
                            handleMute={handleMute}
                            cta={cta}
                            handleLearnMore={handleLearnMore}
                            ad={ad}
                        />
                    )
                }
                sx={{
                    'backgroundColor': '#EDEDF0',
                    'color': '#4A4A68',
                    'fontWeight': 500,
                    'padding': 0,
                    '& .MuiSnackbarContent-message': {
                        padding: '0 !important',
                    },
                }}
            />
        </MuiSnackbar>
    );
};

export interface SnackBarContentProps {
    open: boolean;
    autoHideDuration: number;
    handleClose(): void;
    handleMute(): void;
    cta?(): void;
    handleLearnMore?: () => void;
    ad?: {
        ad_type?: string;
        campaign?: string; // id of the campaign
        content_type?: 'cms' | 'rich_text' | 'key_pairs' | 'plain_text';
        content_value?: string;
        coupon_code?: string;
        name?: string;
    };
}

const SnackbarContent: FC<SnackBarContentProps> = ({
    open,
    autoHideDuration,
    handleClose,
    handleMute,
    cta,
    handleLearnMore,
    ad,
}: SnackBarContentProps) => {
    const [progress, setProgress] = useState(0);
    const [paused, setPaused] = useState(false);

    const handleLearnMoreClick = () => {
        handleLearnMore ? handleLearnMore() : window?.lendica?.ibranch.open('/learn-more');
    };

    const handlePromoClick = () => {
        cta ? cta() : window?.lendica?.ibranch.open('/waitlist-application#landing-section-3');
    };

    useEffect(() => {
        let timer: any;

        const startProgress = () => {
            timer = setInterval(() => {
                if (paused) {
                    return;
                }
                setProgress(prevProgress => prevProgress + (100 * 100) / autoHideDuration);
            }, 100);
        };

        const completeProgress = () => {
            clearInterval(timer);
            setTimeout(() => {
                handleClose();
                setProgress(0);
            }, 500);
        };

        if (open) {
            startProgress();
        }

        if (Math.abs(100 - progress) < 1) {
            completeProgress();
        }

        return () => clearInterval(timer);
    }, [open, autoHideDuration, paused, progress]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            onMouseEnter={() => {
                setPaused(true);
            }}
            onMouseLeave={() => setPaused(false)}
        >
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    'borderRadius': '4px 4px 0px 0px',
                    'backgroundColor': '#9D9DA9',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#0E0E2C !important',
                    },
                }}
            />
            <Box sx={{ pl: 2, pr: 1, pb: 2, pt: 1.5 }}>
                <Box display="flex" flexDirection="row">
                    <CampaignIcon />
                    <Box sx={{ pl: 1.5, pb: 2 }}>
                        <Box sx={{ fontWeight: 500, fontSize: 16, lineHeight: 1.5, color: '#000' }}>
                            {'New in-app finance feature available'}
                        </Box>
                        <Box sx={{ pt: 1, fontWeight: 400, color: '#000', fontSize: 14 }}>
                            <a onClick={handlePromoClick} style={{ cursor: 'pointer' }}>
                                {ad?.content_value ||
                                    `Lendica has helped thousands of businesses pay large bills over time.
                        Imagine how much Lendica can do for you.`}
                            </a>
                            {/* Managing your cash flow is hard. Lendica makes it easy to access fast
                            and affordable finance. Apply in minutes, get instant decisions and{' '}
                            <u>
                                <b onClick={handlePromoClick} style={{ cursor: 'pointer' }}>
                                    try first month free
                                </b>
                            </u>{' '}
                            of any interest or fees. */}
                        </Box>
                    </Box>
                    {handleMute && (
                        <Box sx={{ mt: -1 }}>
                            <Tooltip title="Mute notification">
                                <IconButton onClick={handleMute} color="info" size="small">
                                    <NotificationsRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                    <Box sx={{ mt: -1 }}>
                        {handleClose && (
                            <Tooltip title="Close">
                                <IconButton onClick={handleClose} color="info" size="small">
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" sx={{ ml: 3.5, mr: 1 }}>
                    <Box display="flex">
                        {/* <Button onClick={handleLearnMoreClick} sx={{ color: '#000', mr: 2 }}>
                            Learn More
                        </Button> */}
                        <Button onClick={handlePromoClick} variant="contained" color="secondary">
                            Get started
                        </Button>
                    </Box>
                    {/* <Button sx={{ color: '#0E0E2C' }} onClick={handleMute}>
                        Mute Notifications
                    </Button> */}
                </Box>
            </Box>
        </Box>
    );
};
