import { Box, Button, TextField, Typography } from '@mui/material';
import { AutoGraph, Send, Redeem } from '@mui/icons-material';
import { SocialMediaAndPolicyContainer, ImgWithFallback } from '@lendica/components';

export interface PromotionContainerProps {
    title: string;
    ctaPrimary: {
        label: string;
        onClick: () => void;
    };
    ctaSecondary?: {
        label: string;
        onClick: () => void;
    };
}

export const PromotionContainer = ({
    title,
    ctaPrimary,
    ctaSecondary,
}: PromotionContainerProps) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            flexDirection="column"
        >
            <Typography
                variant="h6"
                sx={{ textAlign: 'center', mt: 3, lineHeight: 1.2 }}
                fontWeight={900}
                fontSize={theme => theme.typography.pxToRem(40)}
            >
                {title}
            </Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                my={2}
                sx={{ width: '424px' }}
            >
                <ImgWithFallback
                    src={'https://static.golendica.com/assets/img/coupon_fmf.webp'}
                    fallback={'https://static.golendica.com/assets/img/coupon_fmf.png'}
                />
            </Box>
            <Typography
                fontWeight={400}
                fontSize={theme => theme.typography.pxToRem(12)}
                sx={{ mx: 4, mb: 4, lineHeight: 1.6, color: '#4A4A68' }}
            >
                *Up to $10,000 of financing available. You will not pay processing fees or any
                interest fees. Subject to underwriting approval.
            </Typography>

            <Button
                fullWidth
                size="large"
                variant="contained"
                startIcon={<Redeem />}
                onClick={ctaPrimary.onClick}
                sx={{ mb: 2, backgroundColor: '#3C41E7' }}
            >
                {ctaPrimary.label}
            </Button>

            {ctaSecondary && (
                <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    startIcon={<AutoGraph />}
                    onClick={ctaSecondary.onClick}
                    sx={{ color: '#3C41E7', borderColor: '#3C41E7' }}
                >
                    {ctaSecondary.label}
                </Button>
            )}
            {/* <Box display="flex" alignItems="center" sx={{ pt: 4 }}>
                <hr style={{ width: '100%', height: 1, backgroundColor: '#9f9fab', border: 0 }} />
                <Typography
                    fontWeight={500}
                    fontSize={theme => theme.typography.pxToRem(13)}
                    sx={{ px: 1 }}
                >
                    Or
                </Typography>
                <hr style={{ width: '100%', height: 1, backgroundColor: '#9f9fab', border: 0 }} />
            </Box>

            <Box>
                <Typography
                    fontWeight={500}
                    fontSize={theme => theme.typography.pxToRem(14)}
                    sx={{ pt: 3, pb: 2, color: '#4A4A68' }}
                >
                    Share this offer with your team
                </Typography>

                <Box display="flex" flexDirection="column">
                    <TextField placeholder="Email" size="small" />

                    <Button
                        variant="outlined"
                        size="small"
                        startIcon={<Send />}
                        sx={{
                            mt: 2,
                            alignSelf: 'end',
                            color: '#0E0E2C',
                            borderColor: '#0E0E2C',
                        }}
                    >
                        Send Now
                    </Button>
                </Box>
            </Box> */}

            <SocialMediaAndPolicyContainer />
        </Box>
    );
};
