import { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { xDomainAuthUrl } from '../api';

export const ClearAccounts = ({ onClear = () => null }: any) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const channel = new MessageChannel();

    useEffect(() => {
        if (iframeRef!.current) {
            function onLoad() {
                channel!.port1.onmessage = onMessage;
                channel!.port1.onmessageerror = onMessageError;
                iframeRef.current!.contentWindow!.postMessage(`clear`, '*', [channel!.port2]);
            }

            function onMessage(e: MessageEvent) {
                if (e.data.type === 'clear') {
                    console.log('ported tokens cleared');
                    onClear();
                    return;
                }
                console.log('message received but not cleared');
                onClear();
            }

            function onMessageError(e: MessageEvent) {
                console.error(e);
                onClear();
            }

            iframeRef.current.addEventListener('load', onLoad);
            return () => {
                iframeRef.current && iframeRef.current.removeEventListener('load', onLoad);
            };
        }
    }, []);

    return (
        <Box sx={{ display: 'none', visibility: 'hidden' }}>
            <iframe
                ref={iframeRef}
                id="lendica-receiver"
                name="lendica-receiver"
                src={xDomainAuthUrl}
                tabIndex={-1}
                aria-hidden="true"
                style={{
                    width: '1px',
                    height: '1px',
                    position: 'absolute',
                    top: '-100px',
                    display: 'none',
                    visibility: 'hidden',
                }}
            ></iframe>
        </Box>
    );
};
