// For lendica.js CDN script deployment, manually set env for dev and prod,
// and deploy to static-idev.golendica and static.golendica separately

enum Environment {
    local = 'local',
    idev = 'idev',
    sandbox = 'sandbox',
    prod = 'prod',
}

export type Env = Environment;
export const env: Env = Environment.idev; // change this to Environment.prod for prod deployment, this is the only place you need to change

export const splitConfig = {
    core: {
        authorizationKey:
            env === Environment.idev
                ? 'p9aq6gjcu5qh00tjqp5nf0csv47nhmp439ej'
                : env === Environment.prod
                ? 'q7kvvl07d8khtd4ckralrfvsajjt8juluhas'
                : 'localhost',
        key: window.localStorage.getItem('ica_vik'),
    },
};

const getBackendURL = (env: Env | undefined) => {
    switch (env) {
        case Environment.idev:
            return 'https://api-dev.golendica.com/api/v1';
        case Environment.sandbox:
            return 'https://api-sandbox.golendica.com/api/v1';
        case Environment.prod:
            return 'https://api.golendica.com/api/v1';
        case Environment.local:
            return 'http://127.0.0.1:8000/api/v1';
        default:
            return 'https://api.golendica.com/api/v1';
    }
};

const getFrontendURL = (env: Env | undefined) => {
    switch (env) {
        case Environment.idev:
            return 'https://paylater.app-idev.golendica.com';
        case Environment.sandbox:
            return 'https://paylater.app-sandbox.golendica.com';
        case Environment.prod:
            return 'https://paylater.app.golendica.com';
        default:
            return 'https://paylater.app.golendica.com';
    }
};

export const frontendUrl = getFrontendURL(env);
export const backendUrl = getBackendURL(env);
