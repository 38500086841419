import { Box, Button, Card, CardContent, Typography } from '@mui/material';

export const GetHelpCard = () => {
    return (
        <Box>
            <Card variant="outlined" sx={{ bgcolor: '#F4F4F6', borderRadius: 3 }}>
                <CardContent>
                    <Typography gutterBottom variant="h6" fontSize="1.15rem" component="div">
                        Have a question? We're here to help.
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom mb={2}>
                        Talk to our live representative to walk you through the process.
                    </Typography>
                    <Button
                        variant="contained"
                        color="info"
                        size="small"
                        component="a"
                        href="https://calendly.com/lendica/lendica-customer-intro"
                        target="_blank"
                    >
                        Schedule a call
                    </Button>
                </CardContent>
            </Card>
        </Box>
    );
};
