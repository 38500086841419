import { Box, Typography } from '@mui/material';

export const Toolbar = ({ title, value, action, className }) => {
    return (
        <Box
            px={2}
            py={1}
            height={64}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={className}
        >
            {title && (
                <Box>
                    <Typography variant="overline" component={Box} height={20}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1">{value}</Typography>
                </Box>
            )}
            {action}
        </Box>
    );
};
