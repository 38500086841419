export * from './init';
import { Emitter } from 'mitt';
import { Events, emitter } from './emitter';

declare global {
    interface Window {
        emitterica: Emitter<Events>;
    }
}
window.emitterica = emitter;

// import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';

// Sentry.init({
//     dsn: 'https://ecfacb81816b42009db7ab00e396a27c@o4504164196352000.ingest.sentry.io/4504334135787520',
//     integrations: [new BrowserTracing(), new Sentry.Replay()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.1,
//     environment: process.env.NODE_ENV,
//     // // This sets the sample rate to be 10%. You may want this to be 100% while
//     // // in development and sample at a lower rate in production
//     // replaysSessionSampleRate: 0.1,
//     // // If the entire session is not sampled, use the below sample rate to sample
//     // // sessions when an error occurs.
//     // replaysOnErrorSampleRate: 1.0,
// });

function invokeFunc<T extends { [key: string]: Function }>(loader: () => Promise<T>) {
    let module: T | undefined;
    let isLoading = false;

    return function <U extends keyof T>(prop: U): T[U] {
        async function importModule() {
            module =
                module ??
                (await (async function () {
                    if (isLoading) return;
                    try {
                        isLoading = true;
                        return loader();
                    } finally {
                        isLoading = false;
                    }
                })());
        }

        return async function (...args: any[]) {
            if (!module) {
                await importModule();
            }
            if (!module?.[prop]) {
                throw 'Unsupported Lendica API';
            }
            return (module[prop] as any)(...args);
        } as unknown as T[U];
    };
}

const checkoutInvokeFunc = invokeFunc(() => import('./Checkout').then(i => i.button));

export const button: typeof import('./Checkout')['button'] = {
    render(...args) {
        return checkoutInvokeFunc<'render'>('render')(...args);
    },
    paylater(...args) {
        return checkoutInvokeFunc<'paylater'>('paylater')(...args);
    },
    checkout(...args) {
        return checkoutInvokeFunc<'checkout'>('checkout')(...args);
    },
    destroy(...args) {
        return checkoutInvokeFunc<'destroy'>('destroy')(...args);
    },
};

export const paylater: typeof button.paylater & { renderButton?: typeof button.render } =
    button.paylater;
paylater.renderButton = button.render;
export const checkout = button.checkout;
export const destroy = button.destroy;

const iBranchInvokeFunc = invokeFunc(() => import('./IBranch').then(i => i.ibranch));

export const ibranch: typeof import('./IBranch')['ibranch'] = {
    render(...args) {
        return iBranchInvokeFunc<'render'>('render')(...args);
    },
    open(...args) {
        return iBranchInvokeFunc<'open'>('open')(...args);
    },
    apply(...args) {
        return iBranchInvokeFunc<'apply'>('apply')(...args);
    },
    openFundNow(...args) {
        return iBranchInvokeFunc<'openFundNow'>('openFundNow')(...args);
    },
    openPayLater(...args) {
        return iBranchInvokeFunc<'openPayLater'>('openPayLater')(...args);
    },
    destroy(...args) {
        return iBranchInvokeFunc<'destroy'>('destroy')(...args);
    },
};

export const apply = ibranch.apply;

export const subscribe = emitter.on.bind(emitter);
export const unsubscribe = emitter.off.bind(emitter);
export const unsubscribeAll = emitter.all.clear.bind(emitter.all);
