import { Box, Typography } from '@mui/material';

export const ErrorMessage = ({ message }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', width: '100%' }}
        >
            <Box>
                <Typography variant="h5" color="text.secondary" align="center" paragraph>
                    {message}
                </Typography>
            </Box>
            <Box my={6}>
                <svg
                    width="110"
                    height="111"
                    viewBox="0 0 110 111"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M91.6465 9.3418H18.9798C13.984 9.3418 9.9419 13.4668 9.9419 18.5085L9.89648 101.008L28.0632 82.6751H91.6465C96.6423 82.6751 100.73 78.5501 100.73 73.5085V18.5085C100.73 13.4668 96.6423 9.3418 91.6465 9.3418ZM59.8548 64.3418H50.7715V55.1751H59.8548V64.3418ZM59.8548 46.0085H50.7715V27.6751H59.8548V46.0085Z"
                        fill="#ED7A56"
                    />
                </svg>
            </Box>
        </Box>
    );
};
