import { Box } from '@mui/material';

export const BottomButton = ({ leftActions, rightActions, className }) => {
    return (
        <Box
            height={64}
            width={'100%'}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={className}
        >
            <Box width={'48.25%'}>{leftActions}</Box>
            <Box width={'48.25%'}>{rightActions}</Box>
        </Box>
    );
};
