import axios from 'axios';
import { setAxiosInterceptors, accessToken, refreshToken } from '@lendica/auth';
import {
    partnerDetails as partnerDetailsAPI,
    promoAds,
    promoVisitor,
    PromoVisitorResponse,
    PromoAd,
} from './IBranch/api/index';
import { initAnalytics, lendicaInitEvent } from './analytics';
import { env, Env, backendUrl } from './constants';

let isAnalyticsInitialized = false;

export interface Credentials {
    partner_name: string;
    partner_company_uuid: number;
}

export interface Config {
    env: Env;
    fullscreen: boolean;
    buttonPositionPercentage: number;
    showSideButton?: boolean;
    partner?: {
        base_url: string;
        has_drawdown: boolean;
        has_fundnow: boolean;
        has_paylater: boolean;
        has_ipaylater: boolean;
        id: string;
        name: string;
    };
    nextStep: 'waitlist' | 'wallet';
    visitor: PromoVisitorResponse;
    ads: PromoAd[];
}

async function auth(credentials: Credentials): Promise<{
    refresh: string;
    access: string;
    status: number;
}> {
    let res = (await axios.post('/auth/partner', credentials)).data;
    return res;
}

async function companyInit(credentials: Credentials) {
    let res = (await axios.post('/company/init', credentials)).data;
    return res;
}

export let isInitialized = false;
export let config!: Config;

const visitorIdKey = 'ica_vik';

async function updateVisitor(partner_name?: string) {
    const visitor = await promoVisitor(window.localStorage.getItem(visitorIdKey), partner_name);
    window.localStorage.setItem(visitorIdKey, visitor.id);
    return visitor;
}

export async function init(
    initCredentials: Credentials | undefined,
    initConfig: Partial<Config> = {}
) {
    if (!isAnalyticsInitialized) {
        initAnalytics();
        isAnalyticsInitialized = true;
    }

    // TODO: should we send init event after updateVisitor,and send visitor id?
    lendicaInitEvent(initCredentials);

    isInitialized = false;

    axios.defaults.baseURL = backendUrl;

    await new Promise(res => {
        if (document.readyState === 'complete') {
            res(null);
            return;
        }
        window.addEventListener('load', () => {
            res(null);
        });
    });

    return new Promise(async (resolve, reject) => {
        if (initCredentials) {
            try {
                const companyInitResponse = await companyInit(initCredentials);
                const { partner_name, partner_company_uuid } = companyInitResponse.company;
                if (!partner_name || !partner_company_uuid) {
                    throw 'Unable to authorize';
                }
                const [authResponse, partnerDetails] = await Promise.all([
                    auth({ partner_name, partner_company_uuid }),
                    partnerDetailsAPI(partner_name),
                ]);
                accessToken.set(authResponse.access);
                refreshToken.set(authResponse.refresh);

                setAxiosInterceptors(backendUrl, axios, () => {});
                isInitialized = true;

                const visitor = await updateVisitor(partner_name);

                config = {
                    fullscreen: false,
                    buttonPositionPercentage: 50,
                    showSideButton: true,
                    env: env,
                    visitor,
                    ads: await promoAds(visitor.id, partner_name),
                    nextStep: 'wallet',
                    partner: partnerDetails.partner,
                    ...initConfig,
                };

                resolve({
                    status: 'success',
                    message: 'Successfully initialized with credentials.',
                });
            } catch (e) {
                console.log(e);
                reject({
                    status: 'error',
                    message: e.message || 'Unable to authorize.',
                });
            }
        } else {
            const visitor = await updateVisitor();
            config = {
                fullscreen: false,
                buttonPositionPercentage: 50,
                showSideButton: true,
                env: env,
                visitor,
                ads: await promoAds(visitor.id),
                nextStep: 'waitlist',
                ...initConfig,
            };
            resolve({
                status: 'waitlist',
                message: 'No credentials provided',
            });
        }
    });
}
