import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    root: {
        textDecoration: ({ underline }) => (underline ? 'underline' : 'none'),
        display: 'flex',
    },
}));

export const LinkButton = ({ className, children, endIcon, underline = true, ...props }) => {
    const classes = useStyles({ underline });

    return (
        <Link component="button" className={clsx(classes.root, className)} {...props}>
            {children}
            {endIcon && endIcon}
        </Link>
    );
};
