import {
    createContext,
    useContext,
    useRef,
    MutableRefObject,
    useEffect,
    FC,
    ReactNode,
} from 'react';
import { Outlet } from 'react-router-dom';
import { xDomainAuthUrl, invalidateToken } from '../api';
import { accessToken, refreshToken } from '../utils/auth';

type ContainerType = {
    // iframeRef: MutableRefObject<HTMLIFrameElement | null>;
    // channel: MessageChannel | null;
    storePortedTokens: (tokens: any) => Promise<boolean>;
    clearAccounts: () => Promise<void>;
};

const AccountContext = createContext<ContainerType | undefined>(undefined);

export const useAccountContext = () => {
    let context = useContext(AccountContext);
    if (context === undefined) {
        throw Error('AccountContext undefined');
    }
    return context;
};

interface AccountProviderProps {
    onAuthSuccess: () => void;
    onAccountClear: () => void;
    children: ReactNode;
}

const channel = new MessageChannel();

export const AccountProvider: FC<AccountProviderProps> = ({
    onAuthSuccess,
    onAccountClear,
    children,
}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const onTokenRecall = async (tokens: any) => {
        try {
            storeLocalTokens(tokens);
            onAuthSuccess();
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const clearAccounts = async (): Promise<any> => {
        try {
            // await invalidateToken(refreshToken.get());
            clearLocalTokens();
            await clearPortedTokens();
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const storePortedTokens = async (tokens: any) => {
        try {
            channel!.port1.postMessage({ type: 'store', ...tokens });
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const clearPortedTokens = async () => {
        channel!.port1.postMessage({ type: 'clear' });
    };

    const storeLocalTokens = (tokens: any) => {
        refreshToken.set(tokens.refresh);
        accessToken.set(tokens.access);
    };

    const clearLocalTokens = () => {
        refreshToken.remove();
        accessToken.remove();
    };

    useEffect(() => {
        if (iframeRef!.current) {
            function onLoad() {
                channel!.port1.onmessage = onMessage;
                channel!.port1.onmessageerror = onMessageError;
                iframeRef.current!.contentWindow!.postMessage(`init`, '*', [channel!.port2]);
            }

            function onMessage(e: MessageEvent) {
                if (e.data.type === 'init') {
                    if (accessToken!.get()) {
                        console.log('abort passing tokens');
                        return;
                    }
                    if (e.data.access && e.data.refresh) {
                        onTokenRecall(e.data);
                    }
                }
                if (e.data.type === 'clear') {
                    console.log('ported tokens cleared');
                    onAccountClear();
                    return;
                }
            }

            function onMessageError(e: MessageEvent) {
                console.error(e);
                onAccountClear();
            }

            iframeRef.current.addEventListener('load', onLoad);
            return () => {
                iframeRef.current && iframeRef.current.removeEventListener('load', onLoad);
            };
        }
    }, []);

    return (
        <AccountContext.Provider
            value={{
                storePortedTokens,
                clearAccounts,
            }}
        >
            <iframe
                ref={iframeRef}
                id="lendica-receiver"
                name="lendica-receiver"
                src={xDomainAuthUrl}
                tabIndex={-1}
                aria-hidden="true"
                style={{
                    width: '1px',
                    height: '1px',
                    position: 'absolute',
                    top: '-100px',
                    display: 'none',
                    visibility: 'hidden',
                }}
            ></iframe>
            {children}
            <Outlet />
        </AccountContext.Provider>
    );
};
