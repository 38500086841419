export interface Approval {
    id: string;
    active: boolean;
    contract_start_date: string;
    term_length_in_days: number;
    qualified_amount: number;
    max_days_outstanding: number;
    origination_fee_percentage: number;
    advance_rate: number;
    funds_available: number;
    funds_in_use: number;
    number_of_active_deals: number;
    total_payoff_amount: number;
    total_in_process_amount: number;
    total_early_payoff_amount: number;
    total_savings_from_payoff: number;
    company: string;
    company_name: string;
    product_name: string;
    payment_schema?: 'BULLET' | 'WEEKLY' | string | null;
}

export interface RateCurve {
    id: string;
    created_date: string;
    updated_date: string;
    day: number;
    daily_rate: number;
    approval: string;
}

export interface Company {
    id: string;
    partner_company_uuid: string | null;
    partner_company_id?: string | null;
    status: CompanyStatus;
    company_name: string;
    company_address: string;
    partner_name: string | null;
    fundnow_status: ProductStatus;
    paylater_status: ProductStatus;
    drawdown_status: ProductStatus;
    first_name: string | null;
    last_name: string | null;
    phone_number: string | null;
    email: string | null;
    bank_name: string | null;
    bank_routing?: string | null;
    bank_account?: string | null;
    data_consent?: boolean;
}

export interface Deal {
    id: string;
    invoice_number: string;
    filename: string | null;
    funded_date: string;
    first_payment_date: string;
    payment_frequency: string;
    number_of_payments: number;
    gross_funded_amount: number;
    origination_fee_amount: number;
    advance_amount: number;
    expected_rate: number;
    disbursement_to_vendor: number;
    disbursement_to_company: number;
    payback_amount: number;
    total_remaining: number;
    total_collected: number;
    periodic_collection_from_company: number;
    total_principal_collected: number;
    total_principal_remaining: number;
    disbursement_to_customer: number;
    collection_from_vendor: number;
    collection_from_company: number;
    collection_from_customer: number;
    status: number;
    deal_status: DealStatus;
    company: string;
    vendor: string | null;
    approval: string;
    company_name: string;
    vendor_name: string;
    product_name: string;
    selected_offer: string | null;
}

interface InvoiceBase {
    id: string;
    status: InvoiceStatus;
    company: string;
    invoice_number: string;
    date: string;
    due_date: string;
    order_date: string | null;
    subtotal: number;
    tax: number;
    total: number;
    filename: string | null;
    payment_terms: string;
    ship_to_address: string;
    shipping_details: {
        shipping_zip: string | null;
        shipping_city: string | null;
        shipping_phone: string | null;
        shipping_address1: string | null;
        shipping_address2: string | null;
        shipping_province: string | null;
        shipping_last_name: string | null;
        shipping_first_name: string | null;
        shipping_province_code: string | null;
    };
    archived: boolean;
    web_url?: string | null;
}

export interface Bill extends InvoiceBase {
    vendor: string;
    vendor_name: string;
    is_checkout: boolean;
    return_url: string | null;
}

export interface Invoice extends InvoiceBase {
    customer: string;
    customer_name: string;
    partner_invoice_uuid: string | null;
    delivery_date: string;
}

export interface Vendor {
    id?: string;
    bank_account?: string;
    bank_name?: string;
    bank_routing?: string;
    company?: string;
    company_address?: string;
    company_name?: string;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
    partner_company_uuid?: string;
    partner_company_id?: string;
    partner_name?: string;
    status?: number;
    fundnow_status?: boolean;
    paylater_status?: boolean;
    drawdown_status?: boolean;
}

export interface Customer {
    id?: string;
    bank_account?: string;
    bank_name?: string;
    bank_routing?: string;
    company?: string;
    company_address?: string;
    company_name?: string;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
    partner_company_uuid?: string;
    partner_company_id?: string;
    partner_name?: string;
    status?: number;
    fundnow_status?: boolean;
    paylater_status?: boolean;
    drawdown_status?: boolean;
}

export enum ApplicationStatus {
    InProgress,
    Submitted,
    Approved,
    Rejected,
}

export enum CompanyStatus {
    Lead,
    Pending,
    Approved,
    Rejected,
}

export enum InvoiceStatus {
    Created,
    Initialized,
    Archived,
}

// export enum DealStatus {
//     Available,
//     Submitted,
//     InProgress,
//     Overdue,
//     Complete,
//     Invalid,
// }

export enum DealStatus {
    'Submitted' = 'Submitted',
    'Completed' = 'Completed',
    'Early Completed' = 'Early Payoff',
    'Current' = 'Current',
    'Grace Period' = 'Grace Period',
    'Late' = 'Late',
    'Default' = 'Default',
}

export enum ProductStatus {
    Inactive,
    Active,
    Paused,
}

export type EventData = {
    eventName: string;
    displayMessage: string;
};
