import NumberFormat from 'react-number-format';

export const PriceFormat = ({ value, scale = 0, noDollar = false }) => {
    return (
        <NumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={noDollar ? '' : '$'}
            decimalScale={scale}
        />
    );
};
