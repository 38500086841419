declare global {
    interface Window {
        gtag: (...args: any[]) => void;
        lendicaConfig: any;
    }
}

export enum MeasuarementId {
    POD = 'G-DRKXGBK0D0', // POD Server-side (iBranch)
}

export const sendGAEvent = async (event: string, params: { [key: string]: any }): Promise<any> => {
    if (!window.gtag) return;
    window.gtag('event', event, {
        send_to: MeasuarementId.POD,
        ...params,
        ...(window?.lendicaConfig || {}),
        ...(window.localStorage.getItem('ica_vik') && {
            visitor_id: window.localStorage.getItem('ica_vik'),
        }),
    });
};
