export interface BankAccount {
    bank_name: string;
    bank_account?: string; // full account number, only when requested through confirm bill details
    bank_routing?: string; // full routing number, only when requested through confirm bill details
}

export interface BankDisplayData {
    name: string; // account name
    account_number: string; // masked account number from lens
    institution: string; // bank name
}

export interface DataConnection {
    id: string;
    active: boolean;
    company: string; // company id
    company_name: string;
    saas_name: string; // plaid
    saas_category: string; // bank
    display_data?: BankDisplayData[] | [];
}

export enum CompanyBankStatus {
    HAS_DEFAULT,
    HAS_SINGLE_ACTIVE_CONNECTED,
    HAS_MULTIPLE_ACTIVE_CONNECTED,
    HAS_INACTIVE_CONNECTED,
    HAS_NONE,
}
