import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const Spinner = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    animation: `${skRotate} 2.0s infinite linear`,
}));

const Dot1 = styled('div')(() => ({
    width: '60%',
    height: '60%',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    backgroundColor: '#3C41E7',
    borderRadius: '100%',
    animation: `${skBounce} 2.0s infinite ease-in-out`,
}));

const Dot2 = styled('div')(() => ({
    width: '60%',
    height: '60%',
    display: 'inline-block',
    position: 'absolute',
    top: 'auto',
    bottom: 0,
    backgroundColor: '#3C41E7',
    borderRadius: '100%',
    animation: `${skBounce} 2.0s infinite ease-in-out`,
    animationDelay: '-1.0s',
}));

const skRotate = keyframes`
    '100%' {
        transform: 'rotate(360deg)';
    }
`;

const skBounce = keyframes`
    '0%, 100%' {
        transform: 'scale(0.0)';
    }
    '50%' {
        transform: 'scale(1.0)';
    }
`;

export const Bounce = ({ dimension = 24 }) => (
    <Spinner
        sx={{
            width: dimension,
            height: dimension,
        }}
    >
        <Dot1 />
        <Dot2 />
    </Spinner>
);
