import axios from 'axios';
import { backendUrl } from '../../constants';
// import { Approval } from '@lendica/utils';

// export const companyDetails = async () => {
//     const response = await axios.get('/company/details');
//     return response.data;
// };

export const partnerDetails = async (partner_name: string) => {
    const response = await axios.get(`/partner?partner_name=${partner_name}`);
    return response.data;
};

// export const approvalList = async () => {
//     const response = await axios.get<Partial<Approval>[]>('/company/approvals');
//     return response.data;
// };

// export const approvalDetails = async (approval_id: string) => {
//     const response = await axios.get(`/company/approvals?id=${approval_id}`);
//     return response.data;
// };

// export const getRateCurves = async () => {
//     const response = await axios.get('/company/ratecurve_backbones');
//     return response.data;
// };

export const signApprovals = async (approval_ids: string[]) => {
    const response = await axios.post('/company/approvals/sign', { approval_ids });
    return response.data;
};

export const getPlaidToken = async (baseURL: string) => {
    const response = await axios.get(baseURL + '/oauth/plaid/get-link-token');
    return response.data.link_token;
};

export const postPlaidToken = async (token: string, company_id: string, baseURL: string) => {
    const response = await axios.post(baseURL + '/oauth/plaid/get_access_token', {
        public_token: token,
        company_id: company_id,
    });
    return response.data;
};

export const postWaitlistForm = async (values: string) => {
    const test = axios.create();
    const response = await test.post('https://operations-ed038.web.app/betalist', { values });
    return response.data;
};

export interface PromoVisitorResponse {
    id: string;
    company: string | null;
    opt_in: boolean;
    last_visit: string | null;
    partner_name: string;
}

export const promoVisitor = async (
    visitor_id: string | null,
    partner_name?: string | null
): Promise<PromoVisitorResponse> => {
    const response = await axios.get<PromoVisitorResponse>(`/promo/visitor`, {
        params: {
            ...(visitor_id && { visitor_id }),
            ...(partner_name && { partner_name }),
        },
    });
    return response.data;
};

// const mockAds = [
//     {
//         name: 'First Month Free Banner',
//         ad_type: 'banner',
//         content_type: 'plain_text',
//         content_value: 'One month of free financing',
//         campaign: 'c8351920-7e7e-4db5-af9b-9b71dc50cc95',
//         coupon_code: 'fmfsummer',
//     },
//     {
//         name: 'First Month Free Banner',
//         ad_type: 'snackbar',
//         content_type: 'plain_text',
//         content_value: 'One month of free financing',
//         campaign: 'c8351920-7e7e-4db5-af9b-9b71dc50cc95',
//         coupon_code: 'fmfsummer',
//     },
//     {
//         name: 'First Month Free Banner',
//         ad_type: 'badge',
//         content_type: 'plain_text',
//         content_value: 'One month of free financing',
//         campaign: 'c8351920-7e7e-4db5-af9b-9b71dc50cc95',
//         coupon_code: 'fmfsummer',
//     },
// ];

export interface PromoAd {
    name: string;
    ad_type: string;
    content_type: string;
    content_value: string;
    campaign: string;
    coupon_code: string;
}

export const promoAds = async (visitor_id: string, partner_name?: string): Promise<PromoAd[]> => {
    const response = await axios.get<PromoAd[]>(
        `/promo/ads?visitor_id=${visitor_id}${partner_name ? `&partner_name=${partner_name}` : ''}`
    );
    return response.data;
};

export const promoOptOut = async (visitor_id: string): Promise<void> => {
    await axios.post<PromoAd>('/promo/visitor', {
        id: visitor_id,
        opt_in: false,
    });
};

export const fundnowRouter = async (
    invoiceId: string,
    total?: number
): Promise<string | undefined> => {
    try {
        const res = (
            await axios.get(`/fundnow/invoice/pull`, {
                params: {
                    partner_invoice_uuid: invoiceId,
                    ...(total && { total }),
                },
            })
        ).data;
        const company = res.company;

        if (company.status === 0) {
            return `/fundnow/marketing/apply/${res.invoice.id}`;
        } else if (company.status === 1) {
            return '/pending';
        } else if (company.status === 2) {
            if (company.fundnow_status === 0) {
                return `/fundnow/marketing/activate/${res.invoice.id}`;
            } else if (company.fundnow_status === 1) {
                return '/fundnow/product-pending';
            } else if (company.fundnow_status === 2) {
                if (res.deal) {
                    if (res.deal.status === 1) {
                        return `/fundnow/deal-pending`;
                    } else if (res.deal.status === 2) {
                        return `/fundnow/deal/${res.deal.id}`;
                    }
                } else {
                    return `/fundnow/invoice/${res.invoice.id}/confirm`;
                }
            } else if (company.fundnow_status === 3) {
                return '/fundnow/product-paused';
            }
        } else if (company.status === 3) {
            return '/fundnow/application-rejected';
        } else {
            throw new Error('Invalid company status');
        }
    } catch (e) {
        console.log(e);
        return '/error';
    }
};

export const paylaterRouter = async (
    billId: string,
    total?: number
): Promise<string | undefined> => {
    try {
        const res = (
            await axios.get(`/paylater/partner`, {
                params: {
                    partner_invoice_uuid: billId,
                    ...(total && { total }),
                },
            })
        ).data;
        const company = res.company;

        if (company.status === 0) {
            return `/paylater/marketing/apply/${res.bill.id}`;
        } else if (company.status === 1) {
            return '/pending';
        } else if (company.status === 2) {
            if (company.paylater_status === 0) {
                return `/paylater/marketing/activate/${res.bill.id}`;
            } else if (company.paylater_status === 1) {
                return '/paylater/product-pending';
            } else if (company.paylater_status === 2) {
                if (res.deal) {
                    if (res.deal.status === 1) {
                        return `/paylater/deal-pending`;
                    } else if (res.deal.status === 2) {
                        return `/paylater/deal/${res.deal.id}`;
                    }
                } else {
                    return `/paylater/bill/${res.bill.id}/confirm`;
                }
            } else if (company.paylater_status === 3) {
                return '/paylater/product-paused';
            }
        } else if (company.status === 3) {
            return '/paylater/application-rejected';
        } else {
            throw new Error('Invalid company status');
        }
    } catch (e) {
        console.log(e);
        return '/error';
    }
};

export const axiosInstance = axios.create({ baseURL: backendUrl });
