import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppContainer, PhoneAuth as PhoneAuthFlow } from './components';
import { setAxiosBaseUrl } from './api';
import { accessToken, refreshToken } from './utils/auth';

export const PhoneAuth = ({
    baseURL,
    onSuccess,
    heading,
    body,
}: {
    baseURL: string;
    onSuccess?: (tokens?: any) => void;
    heading?: string;
    body?: string;
}) => {
    const location: any = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setAxiosBaseUrl(baseURL);
    }, []);

    const setTokens = async (tokens: any) => {
        try {
            refreshToken.set(tokens.refresh);
            accessToken.set(tokens.access);

            !!onSuccess
                ? onSuccess!(tokens)
                : location!.state!.from!.pathname! && navigate(location!.state!.from!.pathname!);
        } catch (error) {}
    };

    return (
        <Routes>
            <Route path="/" element={<AppContainer />}>
                <Route
                    path="/"
                    element={<PhoneAuthFlow setTokens={setTokens} heading={heading} body={body} />}
                />
            </Route>
        </Routes>
    );
};

export { accessToken, refreshToken } from './utils/auth';
export { setAxiosBaseUrl, setAxiosInterceptors, logout, invalidateToken } from './api';
export { ProtectedRoute, ClearAccounts, useAccountContext, AccountProvider } from './components';
