import { useState, useMemo } from 'react';
import { Phone, OneTimePassword } from '.';

const phoneAuthStepsComponents: any = {
    0: Phone,
    1: OneTimePassword,
};

export const PhoneAuth = ({ setTokens, heading, body }: any) => {
    const [step, setStep] = useState(0);
    const [phone, setPhone] = useState('');

    const Component = useMemo(() => {
        return phoneAuthStepsComponents[step];
    }, [step]);

    const onNavBack = () => {
        setStep(step - 1);
    };

    const onNavForward = () => {
        setStep(step + 1);
    };

    return (
        <Component
            phone={phone}
            setPhone={setPhone}
            setTokens={setTokens}
            onNavBack={onNavBack}
            onNavForward={onNavForward}
            heading={heading}
            body={body}
        />
    );
};

export default PhoneAuth;
