import { FC, ReactNode } from 'react';
import { AppBar as MuiAppBar, Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
    Close as CloseIcon,
    NotificationsRounded as NotificationsRoundedIcon,
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

export interface BannerProps {
    position?: 'top' | 'bottom';
    children: ReactNode;
    handleLearnMore?: () => void;
    handleClose?: () => void;
    handleMute?: () => void;
    cta?: () => void;
    ad?: {
        ad_type?: string;
        campaign?: string; // id of the campaign
        content_type?: 'cms' | 'rich_text' | 'key_pairs' | 'plain_text';
        content_value?: string;
        coupon_code?: string;
        name?: string;
    };
}

const animateBackground = keyframes`
    0% {
        color: #fff;
        fill: #fff;
    }
    100% {
        background: #F4F4F6;
        color: #0E0E2C;
        box-shadow: none;
        fill: #0E0E2C;
    }
`;

const slideIn = keyframes`
    from {
        height: 0;
    }
    to {
        height: auto;
    }
`;

const AppBar = styled(MuiAppBar)`
    z-index: 10000;
    background-color: #3c41e7;
    color: #fff;
    fill: #fff;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14),
        0px 1px 8px rgba(0, 0, 0, 0.12);
    animation: ${animateBackground} 1s ease-in-out 1.8s forwards, ${slideIn} 0.3s ease-in forwards;
`;

export const Banner: FC<BannerProps> = ({
    position = 'bottom',
    handleLearnMore,
    handleClose,
    handleMute,
    cta,
    ad,
}) => {
    const styles: any = position === 'top' ? { top: 0 } : { bottom: 0, top: 'unset' };

    const handleLearnMoreClick = () => {
        handleLearnMore ? handleLearnMore() : window?.lendica?.ibranch.open('/learn-more');
    };

    const handlePromoClick = () => {
        cta ? cta() : window?.lendica?.ibranch.open('/waitlist-application#landing-section-3');
    };

    return (
        <AppBar
            sx={{
                backgroundColor: '#3C41E7',
                ...styles,
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ py: 0.5, px: 2 }}
            >
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: ['12px', '14px'],
                            fontWeight: 500,
                            cursor: 'default',
                        }}
                    >
                        <a onClick={handlePromoClick} style={{ cursor: 'pointer' }}>
                            {ad?.content_value ||
                                `Lendica has helped thousands of businesses pay large bills over time.
                        Imagine how much Lendica can do for you.`}
                        </a>
                        {/* Lendica helps you free up cash flow with affordable finance, on demand.{' '}
                        <u>
                            <a onClick={handleLearnMoreClick} style={{ cursor: 'pointer' }}>
                                Learn more
                            </a>
                        </u>{' '}
                        or go straight to{' '}
                        <u>
                            <a onClick={handlePromoClick} style={{ cursor: 'pointer' }}>
                                claiming a month of free invoices
                            </a>
                        </u>
                        . */}
                    </Typography>
                </Box>
                <Box>
                    {handleMute && (
                        <Tooltip title="Mute notification">
                            <IconButton onClick={handleMute} size="small">
                                <NotificationsRoundedIcon style={{ fill: 'inherit' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                    {handleClose && (
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose} size="small">
                                <CloseIcon style={{ fill: 'inherit' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
        </AppBar>
    );
};
