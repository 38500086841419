import { Grid, FormControlLabel, Checkbox, Box, Typography } from '@mui/material';

export const Terms = ({ termsToSign, checkId = '', checked, onChange }) => {
    const getLabel = () => (
        <Typography variant="caption">
            {termsToSign.text}. Please read the{' '}
            <a target="_blank" href={termsToSign.url}>
                Terms and Services
            </a>
            .
        </Typography>
    );

    return (
        termsToSign && (
            <Grid
                container
                justifyContent="flex-end"
                component={Box}
                flex={1}
                alignItems="center"
                flexDirection="column"
            >
                <Grid
                    item
                    component={Box}
                    pt={6}
                    pb={2}
                    sx={{ width: '70%', whiteSpace: 'pre-line' }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={checkId}
                                checked={checked}
                                onChange={onChange}
                                color="primary"
                            />
                        }
                        label={getLabel()}
                    />
                </Grid>
            </Grid>
        )
    );
};
