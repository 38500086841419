import { Box, Typography } from '@mui/material';

export const LayoutPlaceholder = ({ title, subtitle }: { title: string; subtitle: string }) => (
    <Box
        sx={{
            p: 2,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Typography variant="h3" color="#9292A4" paragraph>
            {title}
        </Typography>
        <Box
            sx={{
                // width: '80%',
                width: 500,
                height: '56%',
                background: `center / 50% no-repeat url("https://lendicapublic.s3.amazonaws.com/assets/illustrations/placeholder.svg")`,
            }}
        />
        <Typography variant="body2" color="#4A4A68" paragraph>
            {subtitle}
        </Typography>
    </Box>
);
