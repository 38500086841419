import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { currencyFormatter } from '@lendica/utils';
// import { getPreApproval } from '@lendica/api';

const getDebtIncomeRatio = (debt: number, income: number) => {
    if (income === 0) {
        return 0;
    }
    return debt / income;
};

const getDaysInBusiness = (dateOfIncorporation: string | Date) => {
    const today = new Date();
    if (typeof dateOfIncorporation === 'string') {
        dateOfIncorporation = new Date(dateOfIncorporation);
    }

    if (dateOfIncorporation >= today) {
        return 0;
    }
    return Math.floor((today.getTime() - dateOfIncorporation.getTime()) / (1000 * 60 * 60 * 24));
};

const clamp = (num: number, min: number, max: number) => {
    return Math.min(Math.max(num, min), max);
};

export const usePreApproval = (
    revenue?: number,
    debt?: number,
    dateOfIncorporation?: string | Date,
    creditScore?: number
) => {
    const [hasPreApproval, setHasPreApproval] = useState(false);
    const [debtRatio, setDebtRatio] = useState(0.05);
    const [daysInBusiness, setDaysInBusiness] = useState(720);
    const [preApproval, setPreApproval] = useState(() => {
        try {
            // Try to get from local storage by key
            const item = window.localStorage.getItem('ica_prequal');
            // Parse stored json or if none return masked value
            if (item) {
                setHasPreApproval(true);
                return JSON.parse(item);
            } else {
                return '$XXX,XXX';
            }
        } catch (error) {
            console.error(error);
            return '$XXX,XXX';
        }
    });

    useEffect(() => {
        if (!revenue) {
            return;
        }
        if (!!debt) {
            setDebtRatio(getDebtIncomeRatio(debt, revenue));
        }
        if (!!dateOfIncorporation) {
            setDaysInBusiness(getDaysInBusiness(dateOfIncorporation));
        }

        const amountCalc = computePrequal(revenue, debtRatio, daysInBusiness, creditScore);
        setPreApproval(amountCalc);
        window.localStorage.setItem('ica_prequal', JSON.stringify(amountCalc));
        setHasPreApproval(true);
    }, [revenue, debtRatio, daysInBusiness, debt, dateOfIncorporation, creditScore]);

    const computePrequal = (
        revenue: number,
        debtRatio = 0.05,
        daysInBusiness = 720,
        creditScore = 720
    ) => {
        let points = 0;

        // Add points based on conditions
        if (debtRatio < 0.05) points += 1;
        if (daysInBusiness > 720) points += 1;
        if (creditScore > 720) points += 1;

        // If revenue is less than $10,000, return a minimun amount
        if (revenue < 10000) {
            return 'Less than $1,000';
        }

        // Calculate prequalification number
        let preQualificationNumber = (((10.5 + points) * 2) / 100) * revenue;
        preQualificationNumber = clamp(preQualificationNumber, 1000, 1000000);
        return `${currencyFormatter(Math.round(preQualificationNumber / 1000) * 1000, true)}`;
    };

    return { hasPreApproval, preApproval };
};

export const PreApprovalCard = ({ amount }: { amount: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                py: 3,
                backgroundColor: '#F0F0F9',
                borderRadius: 4,
                mt: 2,
            }}
        >
            <Typography variant="h6" fontWeight={800} fontSize={24} gutterBottom>
                You are pre-approved
            </Typography>
            <Typography variant="h4" color="primary" fontWeight={900} fontSize={42}>
                {`${amount}`}
            </Typography>
        </Box>
    );
};
