import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { forwardRef } from 'react';

const useBodyStyles = makeStyles(() => ({
    root: {
        overflowY: 'auto',
    },
}));

export const Body = forwardRef(({ children, ...props }, ref) => {
    const classes = useBodyStyles();

    return (
        <Box flexGrow="1" className={classes.root} {...props} ref={ref}>
            {children}
        </Box>
    );
});
