import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Backdrop, RoundedButton } from '@lendica/components';
import * as api from '../api';
import { useAppContainerContext } from './AppContainer';

const validationSchema = yup.object({
    otpCode: yup
        .string()
        .test(
            'len',
            'Please provide a valid one-time password',
            val => val!.length! > 2 && val!.length! < 5
        )
        .required('OTP code is required'),
});

export const OneTimePassword = ({ phone, setTokens, onNavBack }: any) => {
    const { setMsg } = useAppContainerContext();
    // const { setMsg, channel } = useAppContainerContext();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            otpCode: '',
        },
        validationSchema: validationSchema,
        onSubmit: async ({ otpCode }) => {
            try {
                setLoading(true);
                const tokens = await api.phoneWithOTP(phone, otpCode);
                // console.log(channel);
                // channel!.port1.postMessage({ type: 'store', ...tokens });
                setTokens(tokens);
            } catch (error) {
                if (error!.response && error!.response!.status === 400) {
                    setMsg('The one-time password is incorrect.');
                }
            } finally {
                setLoading(false);
            }
        },
    });

    const handleResend = async () => {
        try {
            setLoading(true);
            await api.phone(phone);
            setMsg(
                `One-time code sent to ${phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}.`
            );
        } catch (error) {
            console.log(error);
            setMsg('Something went wrong while sending the OTC. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Backdrop loading={loading}>
            <Box
                component="form"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="flex-start"
                pb={4}
                flex={1}
                onSubmit={formik.handleSubmit}
            >
                <IconButton size="small" onClick={onNavBack} sx={{ ml: -0.5 }}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Typography variant="h4" fontSize={16} mb={3} mt={3}>
                    Verify to get started
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        backgroundColor: '#F2F2F8',
                        color: '#282761',
                        fontWeight: 100,
                        borderRadius: '10px',
                        fontSize: 28,
                        mb: 3,
                        p: 2,
                    }}
                >
                    Enter the 4-digit code we just sent to your phone.
                </Typography>

                <TextField
                    label="One-time code"
                    name="otpCode"
                    fullWidth
                    inputProps={{ 'maxLength': 4, 'data-testid': 'otp' }}
                    value={formik.values.otpCode}
                    onChange={formik.handleChange}
                    error={!!(formik.touched.otpCode && formik.errors.otpCode)}
                    helperText={
                        formik.touched.otpCode && formik.errors.otpCode
                            ? formik.errors.otpCode
                            : ' '
                    }
                />
                <Typography
                    variant="body1"
                    onClick={handleResend}
                    sx={{
                        cursor: 'pointer',
                        textAlign: 'right',
                        width: 'fit-content',
                        margin: '0 0 3rem auto',
                        textDecoration: 'underline',
                    }}
                >
                    Resend the code
                </Typography>
                <RoundedButton
                    type="submit"
                    size="medium"
                    color="primary"
                    variant="contained"
                    data-testid="submit"
                    fullWidth
                    sx={{ marginTop: 2 }}
                >
                    Next
                </RoundedButton>
            </Box>
        </Backdrop>
    );
};

export default OneTimePassword;
