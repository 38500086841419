import { Box, Link, Typography } from '@mui/material';
import {
    TimerOutlined,
    MonetizationOn,
    AddModeratorRounded,
    AirplaneTicketRounded,
} from '@mui/icons-material';
import { SocialMediaAndPolicyContainer, ImgWithFallback } from '@lendica/components';

export interface LearnMoreContainerProps {
    title: string;
}

const benefits = [
    {
        icon: TimerOutlined,
        title: 'Instant Decision',
        subTitle: 'Apply in minutes and get instant decision.',
    },
    {
        icon: MonetizationOn,
        title: 'Fair Pricing',
        subTitle: 'Get one of the lowest rates and fees.',
        titleWidth: '50%',
    },
    {
        icon: AddModeratorRounded,
        title: 'No Credit Impact',
        subTitle: 'No impact on your credit even if you’re rejected.',
    },
    {
        icon: AirplaneTicketRounded,
        title: 'More Buying Power',
        subTitle: 'Boost your credit limit as your sales grow.',
    },
];

const actions = [
    {
        image: 'timg_online_conference',
        description: 'Watch how it works',
        link: 'https://youtu.be/oPZ1V5mmnFQ',
    },
    {
        image: 'timg_working_analytics',
        description: 'Try sample offer calculator',
        link: 'https://paylater.app.golendica.com/offer-calculator',
    },
    {
        image: 'timg_coworking_space',
        description: 'Talk to a representative',
        link: 'https://calendly.com/chase-meetinglendica/',
        // function: 'window.HubSpotConversations.widget.open();',
    },
];

const exploreLinks = [
    {
        text: 'Explore A/P financing - PayLater →',
        link: 'https://docsend.com/view/cvbsxpdqhzg94pig',
    },
    {
        text: 'Explore A/R financing - FundNow →',
        link: 'https://docsend.com/view/tr6ccrq9sz8i3age',
    },
    {
        text: 'Explore working capital - DrawDown →',
        link: 'https://docsend.com/view/3n552jrzpbcssi2c',
    },
];

export const LearnMoreContainer = ({ title }: LearnMoreContainerProps) => {
    return (
        <Box display="flex" justifyContent="center" flexDirection="column">
            <Typography
                variant="h6"
                sx={{ textAlign: 'center' }}
                fontWeight={700}
                fontSize={theme => theme.typography.pxToRem(23)}
            >
                {title}
            </Typography>

            <Typography
                sx={{ textAlign: 'center' }}
                fontWeight={400}
                fontSize={theme => theme.typography.pxToRem(13)}
            >
                Fast and affordable payment terms now available.
                <br />
                Select PayLater at checkout.
            </Typography>

            <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-around"
                sx={{ color: '#2E3192', maxWidth: 328, margin: 'auto' }}
            >
                {benefits.map(({ titleWidth, icon: Icon, ...benefit }, index) => (
                    <Typography
                        key={index}
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ width: '38%' }}
                    >
                        <Box sx={{ pt: 4 }}>
                            <Icon />
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                fontWeight: 800,
                                fontSize: 17,
                                py: 1,
                                width: titleWidth ? titleWidth : '100%',
                            }}
                        >
                            {benefit.title}
                        </Box>
                        <Box
                            sx={{
                                textAlign: 'center',
                                fontWeight: 500,
                                color: '#4A4A68',
                                fontSize: 10,
                            }}
                        >
                            {benefit.subTitle}
                        </Box>
                    </Typography>
                ))}
            </Box>

            <Typography
                fontWeight={500}
                fontSize={theme => theme.typography.pxToRem(14)}
                sx={{ pt: 7, pb: 1.5 }}
            >
                Quick actions
            </Typography>

            {actions.map((action, index) => (
                <Link
                    key={index}
                    href={action.link}
                    target="_blank"
                    underline="none"
                    sx={{
                        'display': 'flex',
                        'alignItems': 'center',
                        'border': '0.5px solid #BABAC7',
                        'backgroundColor': '#FFFFFF',
                        'borderRadius': '6px',
                        'my': 0.5,
                        'p': 0.75,
                        '&:hover': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    <Box
                        sx={{
                            boxSizing: 'border-box',
                            width: 28,
                            height: 28,
                            borderRadius: '4px',
                            overflow: 'hidden',
                            backgroundColor: '#EDEDF4',
                            padding: '4px',
                        }}
                    >
                        <ImgWithFallback
                            src={`https://static.golendica.com/assets/img/${action.image}.webp`}
                            fallback={`https://static.golendica.com/assets/img/${action.image}.png`}
                        />
                    </Box>
                    <Typography
                        fontWeight={500}
                        fontSize={theme => theme.typography.pxToRem(14)}
                        sx={{ pl: 1.5 }}
                    >
                        {action.description}
                    </Typography>
                </Link>
            ))}

            <Typography
                fontWeight={500}
                fontSize={theme => theme.typography.pxToRem(14)}
                sx={{ mt: 5, mb: 1 }}
            >
                Explore Lendica
            </Typography>

            {exploreLinks.map(({ link, text }, index) => (
                <Link
                    key={index}
                    href={link}
                    target="_blank"
                    underline="hover"
                    sx={{ color: '#0E5AA1' }}
                >
                    <Typography fontWeight={400} fontSize={theme => theme.typography.pxToRem(14)}>
                        {text}
                    </Typography>
                </Link>
            ))}

            <SocialMediaAndPolicyContainer />
        </Box>
    );
};
