import { Box, CircularProgress } from '@mui/material';

export const LoadingScreen = () => {
    return (
        <Box
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1,
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                minHeight: 600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CircularProgress color="primary" />
        </Box>
    )
}