import { Chip } from '@mui/material';
import { FC } from 'react';

export const ToggleChip: FC<{
    value: boolean;
    onChange(value: boolean): void;
    label: string;
}> = ({ value, onChange, label }) => {
    return (
        <Chip
            label={label}
            variant={value ? 'filled' : 'outlined'}
            color={value ? 'primary' : 'default'}
            sx={
                value
                    ? {
                          border: '1px solid',
                      }
                    : {}
            }
            onClick={() => {
                onChange(!value);
            }}
        />
    );
};
