import { useEffect } from 'react';
import { FeatureLayout, RoundedButton } from '@lendica/components';
import { Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import { emitApi } from '@lendica/api';

export const ProductPending = () => {
    const navigate = useNavigate();
    useEffect(() => {
        emitApi.productPending();
    }, []);

    return (
        <FeatureLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    px: 4,
                }}
            >
                <PreviewIcon color="success" style={{ fontSize: 80 }} />
                <Typography variant="h5" sx={{ mt: 4 }}>
                    We’re reviewing your product request. We’ll send you an email once we approve
                    your request.
                </Typography>

                <RoundedButton
                    size="medium"
                    startIcon={<ArrowBackIcon />}
                    sx={{ mt: 4, mx: 2 }}
                    onClick={() => navigate('/wallet')}
                >
                    Go Back
                </RoundedButton>
            </Box>
        </FeatureLayout>
    );
};
