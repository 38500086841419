import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

const createNumberFormat = ({ format, mask, thousandSeparator, prefix }: any) => {
    return forwardRef(({ onChange, ...props }: any, ref) => (
        <NumberFormat
            {...props}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format={format}
            mask={mask}
            isNumericString
            thousandSeparator={thousandSeparator}
            prefix={prefix}
        />
    ));
};

export const PhoneFormat = createNumberFormat({ format: '+1 (###) ###-####', mask: '_' });
