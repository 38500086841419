import {
    Typography,
    Accordion as MuiAccordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const Accordion = withStyles(() => ({
    root: {
        'borderTop': '1px solid rgba(0, 0, 0, .125)',
        'borderBottom': '1px solid rgba(0, 0, 0, .125)',
        'boxShadow': 'none',
        'borderRadius': 0,
        'width': '100%',
        'marginLeft': 'auto',
        'marginRight': 'auto',
        'marginTop': '2em',
        'marginBottom': '2em',
        'backgroundColor': 'inherit',
        '&:before': {
            display: 'none',
        },
        '&.Mui-expanded': {
            '&.MuiAccordion-root': {
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '2em',
                marginBottom: '2em',
            },
        },
    },
    expanded: {},
}))(MuiAccordion);

export const MoreInformation = ({ items, className }) => {
    return (
        <Accordion square elevation={0} className={className}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="text.secondary" variant="overline">
                    More Information
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {items && items.length && <PinnedSubheaderList items={items} />}
            </AccordionDetails>
        </Accordion>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
        backgroundColor: 'inherit',
    },
    ListSubheader: {
        backgroundColor: 'inherit',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export const PinnedSubheaderList = ({ items }) => {
    const classes = useStyles();

    return (
        <List className={classes.root} subheader={<li />} dense={true}>
            {items.map((item, index) => (
                <li key={`section-${index}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                        <ListSubheader className={classes.ListSubheader}>
                            {item.summary}
                        </ListSubheader>
                        <ListItem>
                            <ListItemText primary={item.details} />
                        </ListItem>
                    </ul>
                </li>
            ))}
        </List>
    );
};
