import axios, { AxiosInstance } from 'axios';
import jwt_decode from 'jwt-decode';
import * as tokens from '../utils/auth';

let axiosInstance: any = null;

export const xDomainAuthUrl = /.app.golendica.com/gi.test(`${window.location}`)
    ? 'https://accounts.golendica.com'
    : 'https://accounts.app-idev.golendica.com';

export const setAxiosBaseUrl = (baseURL: string) => {
    axiosInstance = axios.create({ baseURL });
};

export const phone = async (phone_number: string): Promise<any> => {
    const response = await axiosInstance?.post('/auth/phone', { phone_number });
    return response.data;
};

export const phoneWithOTP = async (phone_number: string, otp: string): Promise<any> => {
    const response = await axiosInstance.post('/auth/phone', {
        phone_number,
        otp,
    });
    return response.data;
};

export const invalidateToken = async (): Promise<any> => {
    return (
        await axios.post('/auth/logout', {
            refresh_token: tokens.refreshToken.get(),
        })
    ).data;
};

export const logout = async (loginURL?: string): Promise<any> => {
    await invalidateToken();
    tokens.accessToken.remove();
    tokens.refreshToken.remove();
    !!loginURL && window.location.replace(`${loginURL}`);
};

export const isTokenExpired = (): boolean => {
    const accessToken = tokens.accessToken.get();
    if (!accessToken) return true;
    const user: { exp: number } = jwt_decode(accessToken);
    return user.exp < new Date().valueOf() / 1000;
};

export const setAxiosInterceptors = (
    baseURL: string,
    axiosInstance: AxiosInstance = axios,
    onRefreshFailure = () => {
        window.location.replace(`${window.location.origin}/login`);
    }
) => {
    axiosInstance.interceptors.request.use(async req => {
        const accessToken = tokens.accessToken.get();
        const refreshToken = tokens.refreshToken.get();

        if (accessToken) {
            req!.headers!.Authorization = `Bearer ${accessToken}`;
        } else {
            return req;
        }

        const user: { exp: number } = jwt_decode(accessToken);

        const isExpired = user.exp < new Date().valueOf() / 1000;

        // If token is not expired, make request
        if (!isExpired) return req;

        // If token is expired, try posting and retreiving new access token
        try {
            const refreshAxiosInstance = axios.create({ baseURL });
            const response = await refreshAxiosInstance.post(`${baseURL}/auth/token/refresh`, {
                refresh: refreshToken,
            });
            if (!!response?.data?.access) {
                tokens.accessToken.set(response?.data?.access);
                req!.headers!.Authorization = `Bearer ${response?.data?.access}`;
            } else {
                throw new Error('Access token not returned from refreshing token.');
            }
        } catch (e) {
            tokens.accessToken.remove();
            tokens.refreshToken.remove();
            onRefreshFailure();
        }
        return req;
    });
    // axios.interceptors.response.use(function (response) {
    //     // Any status code that lie within the range of 2xx cause this function to trigger
    //     // Do something with response data
    //     return response;
    //   }, function (error) {
    //     // Any status codes that falls outside the range of 2xx cause this function to trigger
    //     if (error!.response && error!.response!.status === 401) {
    //         // 401 user does not exist, clear tokens
    //         tokens.accessToken.remove();
    //         tokens.refreshToken.remove();
    //         return Promise.resolve('cleared');
    //     }
    //     // Do something with response error
    //     return Promise.reject(error);
    //   });
};
