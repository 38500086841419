import { EventData } from '@lendica/utils';

export const emitSuccess = (event: EventData) => {
    if (!window!.emitterica) return;
    window.emitterica.emit('success', event);
};

export const emitExit = (event: EventData) => {
    if (!window!.emitterica) return;
    window.emitterica.emit('exit', event);
};

export const emitApi = {
    dealActivated() {
        emitSuccess({
            eventName: 'DEAL_ACTIVATED',
            displayMessage: 'Deal activated successfully.',
        });
    },
    ibranchClosed() {
        emitExit({
            eventName: 'IBRANCH_CLOSED',
            displayMessage: 'iBranch has been closed.',
        });
    },
    applicationPending() {
        emitExit({
            eventName: 'APPLICATION_PENDING',
            displayMessage:
                'Your application is under review. Please check your email for further instructions.',
        });
    },
    applicationRejected() {
        emitExit({
            eventName: 'APPLICATION_REJECTED',
            displayMessage: 'Sorry, your application was not approved at this time.',
        });
    },
    productPending() {
        emitExit({
            eventName: 'PRODUCT_PENDING',
            displayMessage:
                'Your product activation request is under review. We will get back to you shortly.',
        });
    },
    dealExceedsLimit() {
        emitExit({
            eventName: 'DEAL_EXCEEDS_LIMIT',
            displayMessage: 'Deal amount exceeds available credit.',
        });
    },
    internalServerError() {
        emitExit({
            eventName: 'INTERNAL_SERVER_ERROR',
            displayMessage: 'Something went wrong. Please try again later.',
        });
    },
};
