import { useState, createContext, useContext, Dispatch } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Snackbar, SnackbarCloseReason, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Logo } from '@lendica/components';
// import { xDomainAuthUrl } from '../api';

type ContainerType = {
    msg: string | null;
    setMsg: Dispatch<string | null>;
    // iframeRef: MutableRefObject<HTMLIFrameElement | null>;
    // channel: MessageChannel | null;
};

const AppContainerContext = createContext<ContainerType | undefined>(undefined);

export const useAppContainerContext = () => {
    let context = useContext(AppContainerContext);
    if (context === undefined) {
        throw Error('AppContainerContext undefined');
    }
    return context;
};

export const AppContainer = () => {
    const [msg, setMsg] = useState<string | null>(null);
    // const iframeRef = useRef<HTMLIFrameElement>(null);
    // const channel = new MessageChannel();

    const handleClose = (
        event: Event | React.SyntheticEvent<any, Event>,
        reason?: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setMsg(null);
    };

    return (
        <AppContainerContext.Provider
            value={{
                msg,
                setMsg,
                // iframeRef,
                // channel,
            }}
        >
            {/* <iframe
                ref={iframeRef}
                id="lendica-receiver"
                name="lendica-receiver"
                src={xDomainAuthUrl}
                tabIndex={-1}
                aria-hidden="true"
                style={{
                    width: '1px',
                    height: '1px',
                    position: 'absolute',
                    top: '-100px',
                    display: 'none',
                    visibility: 'hidden',
                }}
            ></iframe> */}
            <Box
                display="flex"
                justifyContent="center"
                flex={1}
                sx={{
                    minHeight: '100vh',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                        width: 400,
                        margin: '0 20px',
                        position: 'relative',
                        scrollBehavior: 'smooth',
                        height: 'inherit',
                        ['@media (max-width:400px)']: {
                            width: '100%',
                        },
                    }}
                >
                    <Box
                        py={3}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            '& svg': {
                                display: 'block',
                            },
                            'position': 'sticky',
                            'top': 0,
                            'backgroundColor': 'inherit',
                            'zIndex': 1,
                        }}
                    >
                        <Logo height={18} />
                    </Box>
                    <Outlet />
                    <Snackbar
                        open={!!msg}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        autoHideDuration={6000}
                        onClose={handleClose}
                        message={msg}
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    />
                </Box>
            </Box>
        </AppContainerContext.Provider>
    );
};
