import { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ExpandMore } from './ExpandMore';
import { mapToProductName, numberWithCommas } from './utils';

export const HowItWorksAccordion = ({ approvals }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const productExample = (approval, rateCurve = []) => {
        const { advance_rate, origination_fee_percentage } = approval;

        const [{ day, daily_rate }] = rateCurve.slice(-1);
        const total = 10000;
        const advance = +(total * advance_rate).toFixed(2);
        const processing = +(total * origination_fee_percentage).toFixed(2);
        const rate = daily_rate * day;
        const interest = +(total * rate).toFixed(2);

        return {
            paylater: `If you borrow $10,000 and pay back in ${day} days, Lendica will pay $${numberWithCommas(
                advance
            )} to your vendor, and collect $${numberWithCommas(total - advance)}
            from you today. You will pay $${numberWithCommas(
                processing
            )} in processing and $${numberWithCommas(
                interest
            )} in financing charges for a total of $${numberWithCommas(processing + interest)}.`,
            fundnow: `If you borrow $10,000 and pay back in ${day} days, Lendica will pay you $${numberWithCommas(
                advance
            )} today. You will pay $${numberWithCommas(
                processing
            )} in processing and $${numberWithCommas(
                interest
            )} in financing charges for a total of $${numberWithCommas(processing + interest)}.`,
            drawdown: `If you borrow $10,000 and pay back in ${day} days, Lendica will pay you $${numberWithCommas(
                advance
            )} today. You will pay $${numberWithCommas(
                processing
            )} in processing and $${numberWithCommas(
                interest
            )} in financing charges for a total of $${numberWithCommas(processing + interest)}.`,
        }[approval.product_name];
    };

    return (
        <Typography variant="caption" sx={{ fontWeight: 500, lineHeight: '14px' }}>
            <Box
                sx={() => ({
                    'height': 52,
                    'display': 'flex',
                    'alignItems': 'center',
                    'flexDirection': 'row',
                    'fontSize': '13px',
                    'padding': '0 24px',
                    'margin': '24px 0 0',
                    'background': '#F4F4F6',
                    'borderRadius': '4px',
                    '&:hover': {
                        cursor: 'pointer',
                        background: 'rgba(140, 140, 161, 0.09)',
                    },
                })}
                onClick={handleExpandClick}
            >
                <Box sx={{ fontSize: '13px', fontWeight: 500 }}>How It Works</Box>
                <ExpandMore expand={expanded}>
                    <ArrowDropDownIcon sx={{ fontSize: 18, color: '#4A4A68' }} />
                </ExpandMore>
            </Box>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                sx={{ padding: '0 24px', background: '#F4F4F6' }}
            >
                <Box pb={2.5}>
                    You will decide your payback period for each invoice at the time of financing.
                </Box>
                {approvals.map(approval => (
                    <Box key={approval.id}>
                        <Box pb={1.25}>{mapToProductName(approval.product_name)} Example:</Box>
                        <Box pb={2.5}>{productExample(approval, approval.rateCurve)}</Box>
                    </Box>
                ))}
            </Collapse>
        </Typography>
    );
};
