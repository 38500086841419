import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const FlowDot = styled('div')(() => ({
    'width': '25%',
    'height': '25%',
    'backgroundColor': '#4a4a68',
    'borderRadius': '50%',
    'animation': `${skflow} 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both`,
    '&:nth-of-type(1)': {
        animationDelay: '-0.30s',
    },
    '&:nth-of-type(2)': {
        animationDelay: '-0.15s',
    },
}));

const skflow = keyframes`
    0%, 80%, 100% {
        transform: scale(0.3);
    }
    40% {
        transform: scale(1);
    }
`;

export const Flow = ({ dimension = 24 }) => (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: dimension,
            height: dimension,
        }}
    >
        <FlowDot />
        <FlowDot />
        <FlowDot />
    </Box>
);
