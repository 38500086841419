import axios from 'axios';

const ACTIVE_CAMPAIGN_BASE_URL =
    'https://micro-api-proxy-z8wfa.ondigitalocean.app/proxy/golendica11478.api-us1.com/api/3';
// const ACTIVE_CAMPAIGN_BASE_URL = 'http://localhost:8081/proxy/golendica11478.api-us1.com/api/3';
const ACTIVE_CAMPAIGN_TOKEN =
    '6cb313fd86248147eb3606a8b39264f15b7f08a4144c535744177084ba47c7d80d958b78';

const activeCampaign = axios.create({
    headers: {
        'Api-Token': `${ACTIVE_CAMPAIGN_TOKEN}`,
    },
});

type StatusApplication = 'pre-lead' | 'inprogress' | 'submitted' | 'approved' | 'declined' | 'None';

export const activeCampaignAPI = {
    syncContact: async (
        email: string,
        partnerName: string,
        companyName: string,
        statusApplication?: StatusApplication
    ) => {
        try {
            const res = await activeCampaign.post(ACTIVE_CAMPAIGN_BASE_URL + '/contacts', {
                contact: {
                    email,
                    fieldValues: [
                        {
                            field: 1, // status_application
                            value: statusApplication || 'None',
                        },
                        {
                            field: 2, // partner
                            value: partnerName,
                        },
                        {
                            field: 3, // company name
                            value: companyName,
                        },
                    ],
                },
            });

            return res.data;
        } catch (error) {
            console.log('Error:', error);
        }
    },
    syncAccount: async (body: any) => {
        try {
            const res = await activeCampaign.post(ACTIVE_CAMPAIGN_BASE_URL + '/accounts', body);

            return res.data;
        } catch (error) {
            console.log('Error:', error);
        }
    },
    addContactToList: async (contactId: number, listId: number, status?: number) => {
        try {
            const res = await activeCampaign.post(ACTIVE_CAMPAIGN_BASE_URL + `/contactLists`, {
                contactList: {
                    contact: contactId,
                    list: listId, // 5: Application reminder
                    status: status || 1,
                },
            });

            return res.data;
        } catch (error) {
            console.log('Error:', error);
        }
    },
};
