import { ResponsivePie, PieCustomLayerProps } from '@nivo/pie';
import { Box } from '@mui/material';
import { FC } from 'react';

const CenteredMetric: FC<PieCustomLayerProps<PieChartData>> = ({
    dataWithArc,
    centerX,
    centerY,
}) => {
    let total = 0;
    dataWithArc.forEach(datum => {
        total += datum.value;
    });
    let percent = dataWithArc.find(d => d.id === 'centered')!.value;
    let textColor = dataWithArc.find(d => d.id === 'centered')!.color;

    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            fill={textColor}
            style={{
                fontSize: '16px',
                fontWeight: 600,
                fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    'Inter',
                    '"Segoe UI"',
                    '"Helvetica Neue"',
                    'sans-serif',
                ].join(','),
            }}
        >
            {percent}%
        </text>
    );
};

export interface PieChartData {
    value: number;
    centered?: boolean;
    label: string;
    color: string;
}

export const PieChart: FC<{
    data: PieChartData[];
}> = ({ data }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        innerRadius={0.6}
        activeOuterRadiusOffset={1}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={{ datum: 'data.color' }}
        layers={['arcs', CenteredMetric]}
        tooltip={({ datum: { value, label } }) => (
            <Box
                style={{
                    padding: '4px 8px',
                    color: '#FFFFFF',
                    background: '#616161E5',
                    borderRadius: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        'Inter',
                        '"Segoe UI"',
                        '"Helvetica Neue"',
                        'sans-serif',
                    ].join(','),
                }}
            >
                <span>{label}</span>
                <strong>{value}%</strong>
            </Box>
        )}
    />
);
