import * as coreApi from './core';

// TODO: simplify getting complete approval summary
export const getApprovalSummary = async (product?: string) => {
    const approvals = await coreApi.getApprovalList();
    if (!approvals) return null;

    let activeApprovals = approvals.filter(a => a.active);

    if (product) {
        const filteredApprovals = activeApprovals.filter(a => a.product_name === product);
        if (filteredApprovals) {
            activeApprovals = filteredApprovals;
        }
    }

    if (!activeApprovals.length) return null;

    const approvalDetails = await Promise.all(
        activeApprovals.map(async a => {
            return await coreApi.getApprovalDetails(a.id!);
        })
    );
    const rateCurves = await coreApi.getRateCurves();

    return approvalDetails.map(a => {
        const rcs = rateCurves.filter(r => r.approval === a.id);
        a.rateCurve = rcs.sort((a, b) => a.day - b.day);
        return a;
    });
};
