export const CloverLogo = () => {
    return (
        <svg height={28} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1"
            x="0px" y="0px" viewBox="0 0 88 22" xmlSpace="preserve">
            < g >
                <path
                    fill="#5A5A5A"
                    d="M36.3,14.6c-1.4,1.7-3.4,2.8-5.6,2.8c-3.8,0-6.8-2.7-6.8-6.2c0-1.7,0.7-3.3,2-4.5c1.2-1.2,2.9-1.8,4.6-1.7   c2.2,0,4.3,1,5.6,2.8l-2.5,1.8c-0.7-1.1-1.8-1.8-3.1-1.8c-1.9,0-3.5,1.6-3.5,3.5c0.1,2,1.7,3.5,3.6,3.5c1.3,0,2.5-0.6,3.2-1.7   L36.3,14.6z"
                />
                <rect x="37.7" fill="#5A5A5A" width="3.1" height="17.1" />
                <path
                    fill="#5A5A5A"
                    d="M49.1,14.7c2,0,3.7-1.6,3.8-3.6c-0.1-2-1.8-3.6-3.8-3.6s-3.7,1.6-3.8,3.6C45.4,13.1,47,14.7,49.1,14.7    M49.1,4.9c1.7-0.1,3.4,0.5,4.7,1.7c1.3,1.2,2,2.8,2.1,4.5c-0.1,1.7-0.8,3.4-2.1,4.5c-1.3,1.2-3,1.8-4.7,1.7   c-3.8,0-6.8-2.7-6.8-6.2S45.3,4.9,49.1,4.9"
                />
                <polygon fill="#5A5A5A" points="55.3,5.1 59,5.1 62,11.5 65.2,5.1 68.6,5.1 62,17.8  " />
                <path
                    fill="#5A5A5A"
                    d="M77.5,9.4c-0.5-1.2-1.6-1.9-2.9-1.9c-1.3,0-2.5,0.7-3.1,1.9H77.5L77.5,9.4z M79.5,15.7   c-1.3,1.1-2.9,1.6-4.6,1.6c-3.8,0-6.8-2.7-6.8-6.2c0-1.7,0.7-3.3,1.9-4.5c1.2-1.2,2.9-1.8,4.6-1.7c1.7-0.1,3.3,0.6,4.5,1.8   s1.8,2.8,1.7,4.5V12h-9.6c0.5,1.6,2,2.7,3.7,2.7c1,0,2-0.4,2.8-1.2L79.5,15.7z M82.3,10.4c0-2.9,2.2-5.2,5.7-5.2v2.8   c-0.7,0-1.5,0.3-2,0.8s-0.7,1.3-0.6,2v6.3h-3.1V10.4z"
                />
                <path
                    fill="#228800"
                    d="M9.7,5.6c0-2-1.2-3.7-3-4.5s-3.9-0.4-5.3,1s-1.8,3.5-1.1,5.3s2.5,3,4.5,3h4.9L9.7,5.6z M11.1,5.6   c0-2,1.2-3.7,3-4.5s3.9-0.4,5.3,1s1.8,3.5,1.1,5.3s-2.5,3-4.5,3h-4.9L11.1,5.6z M11.1,16.6c0,2,1.2,3.7,3,4.5   c1.8,0.8,3.9,0.4,5.3-1c1.4-1.4,1.8-3.5,1.1-5.3s-2.5-3-4.5-3h-4.9L11.1,16.6z M4.8,20.1c1.9,0,3.5-1.5,3.5-3.5v-3.5H4.8   c-1.9,0-3.5,1.5-3.5,3.5S2.9,20.1,4.8,20.1z M9.7,16.6c0,2-1.2,3.7-3,4.5c-1.8,0.8-3.9,0.4-5.3-1c-1.4-1.4-1.8-3.5-1.1-5.3   s2.5-3,4.5-3h4.9L9.7,16.6z"
                />
            </g >
        </svg >
    )
}
