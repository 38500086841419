import { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

export enum SaveStatus {
    IDLE = 'idle',
    SUBMITTING = 'submitting',
    SUCCESS = 'success',
    ERROR = 'error',
}

interface SaveFormButtonProps {
    status: SaveStatus;
    onSave: () => void;
}

export const useSaveStatus = (resetTimeout: number) => {
    const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.IDLE);
    useEffect(() => {
        if (saveStatus === SaveStatus.SUCCESS || saveStatus === SaveStatus.ERROR) {
            const timer = setTimeout(() => {
                setSaveStatus(SaveStatus.IDLE);
            }, resetTimeout);
            return () => clearTimeout(timer);
        }
    }, [saveStatus]);
    return { saveStatus, setSaveStatus };
};

export const SaveFormButton = ({ status, onSave, ...props }: SaveFormButtonProps) => {
    return (
        <LoadingButton
            onClick={onSave}
            loading={status === SaveStatus.SUBMITTING}
            endIcon={
                status === SaveStatus.SUCCESS ? (
                    <CheckIcon />
                ) : status === SaveStatus.ERROR ? (
                    <PriorityHighIcon />
                ) : null
            }
            color={
                status === SaveStatus.SUCCESS
                    ? 'success'
                    : status === SaveStatus.ERROR
                    ? 'warning'
                    : 'info'
            }
            disabled
            variant="text"
            sx={{
                width: '30%',
                whiteSpace: 'nowrap',
                // backgroundColor: '#EDEDEF',
            }}
            {...props}
        >
            {status === SaveStatus.ERROR
                ? 'Not synced'
                : status === SaveStatus.SUCCESS
                ? 'Saved'
                : status === SaveStatus.SUBMITTING
                ? ''
                : 'All saved'}
        </LoadingButton>
    );
};
