import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

interface RoundedButtonProps extends ButtonProps {
    target?: string;
    rel?: string;
}

const ThemeButton = styled(Button)<RoundedButtonProps>(({ theme }) => ({
    borderRadius: 30,
    boxShadow: 'none',
}));

export const RoundedButton = (props: RoundedButtonProps) => {
    return <ThemeButton {...props} />;
};
