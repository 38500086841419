import { styled } from '@mui/system';

const Picture = styled('picture')(() => ({
    width: '100%',
    height: 'auto',
    display: 'flex',
}));

const Img = styled('img')(() => ({
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
}));

export const ImgWithFallback = ({ src, fallback, type = 'image/webp', ...delegated }) => {
    return (
        <Picture>
            <source srcSet={src} type={type} />
            <Img src={fallback} {...delegated} />
        </Picture>
    );
};
