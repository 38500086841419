export const isNumber = (value = '') => {
    return /^\d+$/.test(value);
}

export const isNumberOfDigitsInRange = (left: number, right: number) => (value = '') => {
    const digits = value.replace(/\D/g, '');
    return digits.length >= left && digits.length < right;
}

export const isValidRoutingNumber = (value = '') => {
    let n = 0;
    for (let i = 0; i < 8; i += 3) {
        n += parseInt(value.charAt(i), 10) * 3
            + parseInt(value.charAt(i + 1), 10) * 7
            + parseInt(value.charAt(i + 2), 10);
    }

    // If the resulting sum is an even multiple of ten (but not zero),
    // the aba routing number is good.
    return n != 0 && n % 10 == 0;
};

export const hasNumberOfDigits = (numberOfDigits: number) => (value = '') => {
    return value.replace(/\D/g, '').length === numberOfDigits;
}
