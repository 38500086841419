import { InvoiceStatus, DealStatus } from './sharedTypes';

export const billStatusToColor = {
    [InvoiceStatus.Created]: 'text',
    [InvoiceStatus.Initialized]: 'info',
    [InvoiceStatus.Archived]: 'error',
    [DealStatus.Submitted]: 'info',
    [DealStatus.Current]: 'success',
    [DealStatus.Late]: 'warning',
    [DealStatus['Grace Period']]: 'error',
    [DealStatus['Early Completed']]: 'info',
    [DealStatus.Completed]: 'info',
    [DealStatus.Default]: 'error',
};

export const billStatusToLabel = {
    [InvoiceStatus.Created]: 'Available',
    [InvoiceStatus.Initialized]: 'Initialized',
    [InvoiceStatus.Archived]: 'Archived',
};
