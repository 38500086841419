import { FC } from 'react';
import { useField } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

export const FormikTextField: FC<
    Omit<TextFieldProps, 'name' | 'value' | 'onChange' | 'error'> & { name: string }
> = props => {
    const [field, meta] = useField(props.name);

    return (
        <TextField
            {...props}
            size="small"
            value={field.value ?? ''}
            onChange={field.onChange}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error ? meta.error : props.helperText}
        />
    );
};

export const FormikDatePicker: FC<
    Omit<DatePickerProps<any, any>, 'renderInput' | 'value' | 'onChange'> & {
        name: string;
        TextFieldProps: TextFieldProps;
    }
> = props => {
    const [field, meta, helpers] = useField(props.name);

    return (
        <DatePicker
            {...props}
            renderInput={(
                inputProps: JSX.IntrinsicAttributes &
                    JSX.LibraryManagedAttributes<
                        (props: TextFieldProps) => JSX.Element,
                        TextFieldProps
                    >
            ) => (
                <TextField
                    {...inputProps}
                    {...props.TextFieldProps}
                    size="small"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={props.TextFieldProps.helperText || (meta.touched && meta.error)}
                />
            )}
            PopperProps={{ disablePortal: true }}
            value={field.value}
            onChange={(date: any) => helpers.setValue(date)}
        />
    );
};
