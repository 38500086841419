import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { numberWithCommas } from './utils';
import { FundNowLogo, PayLaterLogo, DrawDownLogo } from '@lendica/components';

export const FundsAvailableCard = ({ approvals }) => {
    return (
        <Box
            sx={{
                borderRadius: '8px',
                border: '1px solid #3C41E7',
                background: '#F5F5FF',
            }}
        >
            {approvals.map((approval, index) => (
                <Box key={approval.id}>
                    {index > 0 && <Divider sx={{ color: '#CACAFF', margin: '0 16px' }} />}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '12px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {approval.product_name === 'fundnow' && <FundNowLogo />}
                            {approval.product_name === 'paylater' && <PayLaterLogo />}
                            {approval.product_name === 'drawdown' && <DrawDownLogo />}
                        </Box>
                        <Typography
                            sx={{
                                color: '#3C41E7',
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontSize: '24px',
                                fontWeight: 800,
                            }}
                        >
                            ${numberWithCommas(approval.funds_available)}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
