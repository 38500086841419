import { Box, Button, CircularProgress } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SidePanelLayoutProps {
    children: ReactNode;
    loading?: boolean;
    redirectRoute: string;
    collapsible?: boolean;
}

export const CircularIndeterminate = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#9292a4',
            }}
        >
            <CircularProgress color="inherit" />
        </Box>
    );
};

export const SidePanelLayout: FC<SidePanelLayoutProps> = ({
    children,
    loading,
    redirectRoute,
    collapsible,
}) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                py: 2,
                px: 4,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: 300,
                boxSizing: 'border-box',
            }}
            className={`aside ${collapsible ? 'collapsible' : ''}`}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    pb: 1,
                }}
            >
                <Button onClick={() => navigate(redirectRoute)}>Close</Button>
            </Box>
            {loading ? <CircularIndeterminate /> : children}
        </Box>
    );
};
