import { FC, ReactNode } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { currencyFormatter } from '@lendica/utils';

import { UsageChart } from './UsageChart';

export const FeatureHeader: FC<{
    name: ReactNode;
    available: number;
    total: number;
    unpaid: number;
    entityNumber: number;
    entity: string;
}> = ({ name, available, total, unpaid, entityNumber, entity }) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3} mr={-1}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                {/* <Typography variant="h5" color="primary"> */}
                {name}
                {/* </Typography> */}
                <Box display="flex" mt={3}>
                    <Box mr={isMd ? 4 : 2.5}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Credit Available
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {currencyFormatter(available, true)}
                        </Typography>
                        <Typography variant="caption">
                            of {currencyFormatter(total, true)} credit line
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">
                            Unpaid
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {currencyFormatter(unpaid, true)}
                        </Typography>
                        <Typography variant="caption">
                            of {entityNumber} {entity} in total
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box style={{ width: 140, height: 140 }}>
                <UsageChart total={total} available={available} />
            </Box>
        </Box>
    );
};
