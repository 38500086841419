import {
    Box,
    Grid,
    Typography,
    IconButton,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    Slider,
    Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.background.card,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        display: 'inline-flex',
    },
}));

export const Overlay = ({ title, handleClose, children }) => {
    const classes = useStyles();

    return (
        <Box mx={2} display="flex" flexDirection="column" flex={1}>
            <Box mb={1}>
                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item>
                        <IconButton
                            edge="start"
                            className={classes.closeButton}
                            onClick={handleClose}
                            size="small"
                            aria-label="close overlay"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" color="primary">
                            {title.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.container} flex={1}>
                {children}
            </Box>
        </Box>
    );
};

export const OverlayFieldset = ({ subtitle, children }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            component={Box}
            my={2}
            py={2}
        >
            <Grid item>
                <Typography
                    variant="h5"
                    color="text.primary"
                    component="h5"
                    align="center"
                    paragraph
                >
                    {subtitle}
                </Typography>
            </Grid>
            {children}
        </Grid>
    );
};

export const OverlayKeypoint = ({ summary, details }) => {
    return (
        <Grid item component={Box} py={2}>
            <Typography variant="subtitle2" color="text.secondary" align="center" gutterBottom>
                {summary}
            </Typography>
            <Typography variant="h4" color="primary" align="center" paragraph>
                {details}
            </Typography>
        </Grid>
    );
};

export const OverlaySelect = ({ summary, inputLabel, options, value, handleChange }) => {
    return (
        <Grid item component={Box} py={2}>
            <Typography variant="subtitle2" color="text.secondary" align="center" gutterBottom>
                {summary}
            </Typography>
            <FormControl fullWidth>
                <InputLabel id={`overlay-select-${inputLabel}`}>{inputLabel}</InputLabel>
                <Select
                    labelId={`overlay-select-${inputLabel}`}
                    id={`select-${inputLabel}`}
                    value={value}
                    label={inputLabel}
                    onChange={handleChange}
                    variant="standard"
                >
                    {options &&
                        options.length &&
                        options.map((option, index) => (
                            <MenuItem key={`select-menu-${index}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Grid>
    );
};

export const OverlayOption = ({ summary, min, max, marks, value, handleChange }) => {
    return (
        <Grid item component={Box} py={2}>
            <Typography variant="subtitle2" color="text.secondary" align="center" gutterBottom>
                {summary}
            </Typography>
            <Slider
                defaultValue={max}
                step={null}
                min={min}
                max={max}
                marks={marks}
                valueLabelDisplay="off"
                color="primary"
                value={value}
                onChangeCommitted={handleChange}
            />
        </Grid>
    );
};

export const OverlaySwitch = ({ summary, checked, onChange, name, labels }) => {
    return (
        <Grid item container direction="column" alignItems="center" component={Box} py={2}>
            <Typography variant="subtitle2" color="text.secondary" align="center" gutterBottom>
                {summary}
            </Typography>
            <Typography component="div">
                <Grid
                    component="label"
                    container
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>
                        <Switch
                            checked={checked}
                            onChange={onChange}
                            name={name}
                            color="primary"
                        />
                    </Grid>
                    <Grid item>{checked ? labels[0] : labels[1]}</Grid>
                </Grid>
            </Typography>
        </Grid>
    );
};

export const OverlayAction = ({ action, actionId = '', disabled, onClick }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            component={Box}
            my={6}
        >
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    id={actionId}
                    disabled={disabled}
                    onClick={onClick}
                >
                    {action}
                </Button>
            </Grid>
        </Grid>
    );
};
