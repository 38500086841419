import { useState } from 'react';
import { Box, Tooltip, Collapse, Typography, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ExpandMore } from './ExpandMore';
import { formatDate, calculateEndDate, numberWithCommas, mapToProductName } from './utils';

export const ApprovalAccordion = ({ approval }) => {
    const [expanded, setExpanded] = useState(false);

    if (!approval || !approval.rateCurve) {
        return null;
    }

    const startDate = new Date(approval.contract_start_date);
    const endDate = calculateEndDate(startDate, approval.term_length_in_days);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
            <Box
                sx={{
                    'height': 56,
                    'alignItems': 'center',
                    'flexDirection': 'row',
                    'display': 'flex',
                    'padding': '0 24px',
                    'fontSize': '12px',
                    'fontWeight': 400,
                    'backgroundColor': '#F4F4F6',
                    'borderRadius': '4px',
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: 'rgba(140, 140, 161, 0.09)',
                    },
                }}
                onClick={handleExpandClick}
            >
                <Box sx={{ fontSize: '13px', fontWeight: 500 }}>
                    My {mapToProductName(approval.product_name)} approval
                </Box>
                <ExpandMore expand={expanded}>
                    <ArrowDropDownIcon sx={{ fontSize: 18, color: '#4A4A68' }} />
                </ExpandMore>
            </Box>
            <Collapse
                in={expanded}
                timeout="auto"
                unmountOnExit
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#4A4A68',
                    padding: '0 24px 20px',
                    backgroundColor: '#F4F4F6',
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: 24, fontWeight: 300 }}
                >
                    <Box>ID. {approval.id.slice(0, 8)}</Box>
                    <Box>
                        {formatDate(startDate)} - {formatDate(endDate)}
                    </Box>
                </Box>
                <Divider
                    sx={{ backgroundColor: '#8C8CA1', margin: '4px 0', border: 0, height: '0.5px' }}
                />
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: 24 }}
                >
                    <Box>Qualified amount</Box>
                    <Box>${numberWithCommas(approval.qualified_amount)}</Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: 24 }}
                >
                    <Box display="flex" alignItems="center">
                        Advance rate
                        <Tooltip
                            arrow
                            placement="top"
                            PopperProps={{ style: { width: 200 } }}
                            title={
                                <>
                                    <i>Advance Rate</i> - The percentage you will receive from
                                    Lendica for every deal.
                                </>
                            }
                        >
                            <ErrorOutlineIcon sx={{ fontSize: 13, paddingLeft: '2px' }} />
                        </Tooltip>
                    </Box>
                    <Box>{approval.advance_rate * 100}%</Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: 24 }}
                >
                    <Box display="flex" alignItems="center">
                        Processing fee
                        <Tooltip
                            arrow
                            placement="top"
                            PopperProps={{ style: { width: 200 } }}
                            title={
                                <>
                                    <i>Processing Fee</i> - A one-time fee charged for every deal
                                    based on the deal amount.
                                </>
                            }
                        >
                            <ErrorOutlineIcon sx={{ fontSize: 13, paddingLeft: '2px' }} />
                        </Tooltip>
                    </Box>
                    <Box>{approval.origination_fee_percentage * 100}%</Box>
                </Box>

                <Box display="flex" justifyContent="space-between" sx={{ marginTop: '24px' }}>
                    <Box display="flex" alignItems="center">
                        Financing fees
                        <Tooltip
                            arrow
                            placement="top"
                            PopperProps={{ style: { width: 200 } }}
                            title={
                                <>
                                    <i>Financing Fees</i> - Rate charged on your payback amount. See
                                    how it works for details.
                                </>
                            }
                        >
                            <ErrorOutlineIcon sx={{ fontSize: 13, paddingLeft: '2px' }} />
                        </Tooltip>
                    </Box>

                    <Box>Period</Box>
                    <Box>Rate</Box>
                </Box>
                <Divider
                    sx={{ backgroundColor: '#8C8CA1', margin: '4px 0', border: 0, height: '0.5px' }}
                />
                {approval.rateCurve.map(r => (
                    <Box key={r.id} display="flex" justifyContent="right">
                        <Box>{r.day} days</Box>
                        <Box sx={{ textAlign: 'right', width: '120px' }}>
                            {+(r.daily_rate * r.day * 100).toFixed(2)}%
                        </Box>
                    </Box>
                ))}
                <Divider
                    sx={{
                        backgroundColor: '#8C8CA1',
                        marginTop: '4px',
                        border: 0,
                        height: '0.5px',
                    }}
                />
                <Box
                    variant="caption"
                    display="flex"
                    justifyContent="space-between"
                    sx={{ marginTop: '24px', fontWeight: 700, color: '#0E0E2C' }}
                >
                    <Box>Balance</Box>
                    <Box>${numberWithCommas(approval.funds_in_use, 2)}</Box>
                </Box>
                <Box
                    variant="caption"
                    display="flex"
                    justifyContent="space-between"
                    sx={{ fontWeight: 700, color: '#0E0E2C' }}
                >
                    <Box>Credit available</Box>
                    <Box>${numberWithCommas(approval.funds_available, 2)}</Box>
                </Box>
            </Collapse>
        </Typography>
    );
};
