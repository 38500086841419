import { Box } from '@mui/material';
import { FC } from 'react';
import { LoadingScreen } from './LoadingScreen';

export const FeatureLayout: FC<{ loading?: boolean; children: any; fullHeight?: boolean }> = ({
    children,
    loading,
    fullHeight = false,
}) => {
    return (
        <Box
            sx={{
                overflow: 'auto',
                flexShrink: 0,
                bgcolor: 'background.paper',
                height: '100%',
                gridArea: 'main',
            }}
            className={fullHeight ? 'tallMain' : undefined}
        >
            {loading ? <LoadingScreen /> : children}
        </Box>
    );
};
